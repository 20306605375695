import React, {Component} from 'react';
import {NavLink} from 'mdbreact';
//import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../_helpers';
import { bindActionCreators } from "redux";
import { userActions } from '../_actions';
import './notifications.css';
import WebNotification from 'react-web-notifications';
import muzikol from './../images/muzikollogo.jpg';

class Notifications extends Component {
	constructor(props) {
        super(props);
        this.props.updateUnreadNotifCount(); // sets unread notification count to zero
        this.props.getUser(); // get this user
        this.props.getNotifications();
		this.state = {
            notifications: '',
            newNotifications: '',
		}
	}

    componentDidUpdate(prevProps){
        if((this.props.notifications !== prevProps.notifications) && this.props.counterUser ){
            //get the last 20 messages
            let notifications = this.state.notifications.slice(-30);
            // filter the ones not read(viewed)
            let newNotifications = notifications.filter(notification => notification.created_at > this.props.counterUser.user.last_notification);
            this.setState({
                newNotifications: newNotifications.slice(-3) // get the last 3 most recent and not read notifications
            });
        }
    }

    componentWillReceiveProps(props){
        this.setState({
            notifications: props.notifications
        })
    }

    render() {
        const {notifications, newNotifications} = this.state;
        const anotifications = notifications ?
            notifications.length ? (
                notifications.map((notification, index)=>{
                    return (
                        <NavLink to={notification.urlNoti ? notification.urlNoti : '/page-not-found'} className="p-0 m-0" onClick={() => history.push(notification.urlNoti)} title={notification.urlNoti ? 'www.muzikol.com'+notification.urlNoti : 'www.muzikol.com/page-not-found'} key={notification.idNotif}>
                            <div className={notification.viewed ? 'py-2 px-2 notification' : 'py-2 px-2 not-viewed notification'}>
                                {notification.topic}
                            </div>
                        </NavLink>
                    )
                })
            ) : (
                <div>no notification yet</div>
            )
        :
            <div>Loading...</div>
    	return (
            <div>
                {anotifications}
                {newNotifications &&
                    newNotifications.map((notification, index)=>{
                        return (
                            <WebNotification
                                key={notification.idNotif}
                                title={notification.topic} // the title prop is required
                                icon={muzikol}
                                body={notification.message}
                                timeout={2000}
                                onClickFn={ () => window.open(notification.urlNoti, '_blank') } // open your own site on notification click
                            />
                        )
                    })
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {counterUser} = state.getusertion;
    const {notifications} = state.getnotifications;
    return {
        counterUser,
        notifications
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({...userActions}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);