import { apiConstants } from '../_constants';
//import { authHeader } from '../_helpers';

export const postService = {
    createpost,
    getposts,
    getdetails,
    likepost,
    commentpost,
    replycomment,
    likecomment,
    getreplycomments,
    getallartists,
    getallreleases,
    getallevents,
    deletepost,
    reportpost,
    updatepost
};

// create a post
function createpost(newpost, tags) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
//        withCredentials: true,
//        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
//            'Authorization':'Bearer token2',
          },
//        body: JSON.stringify({ details, tags, location, postfiles })
        body: JSON.stringify({details: newpost.details, location: newpost.location, tags: tags })
    };
    return fetch(apiConstants.API_POST_URL+'createpost?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//gets post data
function getposts(limit, userId) {
let user = JSON.parse(localStorage.getItem('user'));
 const requestOptions = {
       method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: userId, limit: limit })
    };
    return fetch(apiConstants.API_POST_URL+'getposts?access-token='+user.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}

//get details on a post
function getdetails(postId) {
let user = JSON.parse(localStorage.getItem('user'));
 const requestOptions = {
       method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ postId })
    };
    return fetch(apiConstants.API_POST_URL+'getdetails?access-token='+user.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}

//like a post
function likepost(postId, liked) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({ postId, liked })
    };
    return fetch(apiConstants.API_POST_URL+'comment?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// comment a post
function commentpost(postId, comment) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ postId, comment })
    };
    return fetch(apiConstants.API_POST_URL+'comment?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//reply to a comment
function replycomment(parentId, comment) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ parentId, comment })
    };
    return fetch(apiConstants.API_POST_URL+'replycomment?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// get the replies to a comment
function getreplycomments(parentId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ parentId })
    };
    return fetch(apiConstants.API_POST_URL+'getreplycomments?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//like a comment
function likecomment(parentId, liked) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({ parentId, liked })
    };
    return fetch(apiConstants.API_POST_URL+'replycomment?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//get all artists
function getallartists() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
/*        headers: {
            'Content-Type': 'application/json',
          },*/
//        body: JSON.stringify({})
    };
    return fetch(apiConstants.API_POST_URL+'getallartists?access-token='+user.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}

//get all artists
function getallreleases() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',

    };
    return fetch(apiConstants.API_POST_URL+'getallreleases?access-token='+user.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}
//get all artists
function getallevents() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
    };
    return fetch(apiConstants.API_POST_URL+'getallevents?access-token='+user.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}

//delete a post
function deletepost(postId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({postId})
    };
    return fetch(apiConstants.API_POST_URL+'deletepost?access-token='+user.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}

// report a post
function reportpost(report, postId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({details: report.details, category: report.category, postId})
    };
    return fetch(apiConstants.API_POST_URL+'reportpost?access-token='+user.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}

// update a post
function updatepost(newpost, tags) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({postId: newpost.postId, details: newpost.details, location: newpost.location, tags: tags })
    };
    return fetch(apiConstants.API_POST_URL+'updateinfo?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
