import { userConstants } from '../_constants';

export function getusertion(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_USER_SUCCESS:  
      return {
        userState: true,
        counterUser: action.user,
      };
    case userConstants.GET_USER_FAILURE:
      return {
          userState: false,
          error:action.error 
      };
    default:
      return state
  }
}

// get the notifications of a user
export function getnotifications(state={}, action){
    switch (action.type) {
        case userConstants.GET_USER_NOTIFICATIONS_SUCCESS:
            return {
                notificationState: true,
                notifications: action.notifications
            };
        case userConstants.GET_USER_NOTIFICATIONS_FAILURE:
            return {
                notificationState: false,
            };
        default:
          return state
    }
}

// mark a notification as viewed
export function navbadges(state={}, action){
    switch (action.type) {
        case userConstants.USER_BADGES_SUCCESS:
            return {
                notificationViewState: true,
                navbadgesinfo: action.navbadgesinfo
            };
        case userConstants.VIEW_NOTIFICATION_FAILURE:
            return {
                notificationViewState: false,
            };
        default:
          return state
    }
}
