export const userConstants = {   
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    TEST_VALIDATE:'TEST_VALIDATE',
    PROFILE_SUCCESS: 'PROFILE_INFO_SUCCESS',
    PROFILE_FAILURE: 'PROFILE_INFO_FAILURE',
    USER_SUCCESS: 'USER_GET_SUCCESS',
    USER_FAILURE: 'USER_GET_FAILURE',
    GET_USER_SUCCESS: 'OTHER_USER_GET_SUCCESS',
    GET_USER_FAILURE: 'OTHER_USER_GET_FAILURE',
    RELATIONS_SUCCESS:'RELATIONS_SUCCESS',
    RELATIONS_FAILURE:'RELATIONS_FAILURE',
    USER_UPDATE_SUCCESS:'USER_UPDATE_SUCCESS',
    USER_PARTIAL_SUCCESS:'USER_PARTIAL_SUCCESS',
    USER_NOTIFICATIONS_SUCCESS:'USER_NOTIFICATIONS_GET_SUCCESS',
    USER_NOTIFICATIONS_FAILURE:'USER_NOTIFICATIONS_GET_FAILURE',
    GET_USER_NOTIFICATIONS_SUCCESS:'GET_USER_NOTIFICATIONS_SUCCESS',
    GET_USER_NOTIFICATIONS_FAILURE:'GET_USER_NOTIFICATIONS_FAILURE',
     GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS:'NOTIFICATIONS_UNREAD_COUNT_GET_SUCCESS',
    GET_UNREAD_NOTIFICATIONS_COUNT_FAILURE:'NOTIFICATIONS_UNREAD_COUNT_GET_FAILURE',
    USER_BADGES_SUCCESS:'USER_BADGES_SUCCESS',
    LOGOUT: 'USERS_LOGOUT',
};
