import { relationsConstants,userConstants } from '../_constants';
import { relationsService } from '../_services';
import { alertActions } from './';

export const relationsActions = {
   friendrequest,
   follow,
   block,
   unblock,
   getfollowers,
   getfollowings,
   getfriends,
   getFriendRequest,
   acceptFriend,
   declineFriend,
   unfollow
};

//this function is to create specialbusiness request
function friendrequest(username){
    return dispatch => {
        relationsService.friendrequest(username)
            .then(
                datas => {
                    dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(datas) { return { type: relationsConstants.FREQUEST_SUCCESS, datas } }
    function failure(error) { return { type: relationsConstants.FREQUEST_FAILURE, error } }
}

//this function is to follow an artist
function follow(username){
    return dispatch => {
        relationsService.follow(username)
            .then(
                datas => { 
                    dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(datas) { return { type: relationsConstants.FOLLOW_SUCCESS, datas } }
    function failure(error) { return { type: relationsConstants.FOLLOW_FAILURE, error } }
}


//this function is to follow an artist
function unfollow(username){
    return dispatch => {
        relationsService.unfollow(username)
            .then(
                datas => {
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(datas) { return { type: relationsConstants.FOLLOW_SUCCESS, datas } }
    function failure(error) { return { type: relationsConstants.FOLLOW_FAILURE, error } }
}


//this function is to follow an artist
function block(username){
    return dispatch => {
        relationsService.block(username)
            .then(
                datas => { 
                    dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(datas) { return { type: relationsConstants.FOLLOW_SUCCESS, datas } }
    function failure(error) { return { type: relationsConstants.FOLLOW_FAILURE, error } }
}

//this function is to follow an artist
function unblock(username){
    return dispatch => {
        relationsService.unblock(username)
            .then(
                datas => { 
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(datas) { return { type: relationsConstants.FOLLOW_SUCCESS, datas } }
    function failure(error) { return { type: relationsConstants.FOLLOW_FAILURE, error } }
}

//get the users followers
function getfollowers(username){
    return dispatch => {
        relationsService.getfollowers(username)
            .then(
                datas => {
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(users) { return { type: userConstants.RELATIONS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.RELATIONS_FAILURE, error } }
}


//get the user's following
function getfollowings(username){
    return dispatch => {
        relationsService.getfollowings(username)
            .then(
                datas => {
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(users) { return { type: userConstants.RELATIONS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.RELATIONS_FAILURE, error } }
}

//get the user's following
function getfriends(username){
    return dispatch => {
        relationsService.getfriends(username)
            .then(
                datas => { 
                    dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(users) { return { type: userConstants.RELATIONS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.RELATIONS_FAILURE, error } }
}

//get all friend requests
function getFriendRequest(username){
    return dispatch => {
        relationsService.getFriendRequest(username)
            .then(
                datas => { 
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(users) { return { type: relationsConstants.GET_ALL_FRIEND_REQUEST_SUCCESS, users } }
    function failure(error) { return { type: relationsConstants.GET_ALL_FRIEND_REQUEST_FAILURE, error } }
}

//accept friend requests
function acceptFriend(username){
    return dispatch => {
        relationsService.acceptFriend(username)
            .then(
                datas => { 
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(response) { return { type: relationsConstants.ACCEPT_FRIEND_REQUEST_SUCCESS, response } }
    function failure(error) { return { type: relationsConstants.ACCEPT_FRIEND_REQUEST_FAILURE, error } }
}

//decline friend requests
function declineFriend(username){
    return dispatch => {
        relationsService.declineFriend(username)
            .then(
                datas => { 
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(response) { return { type: relationsConstants.DECLINE_FRIEND_REQUEST_SUCCESS, response } }
    function failure(error) { return { type: relationsConstants.DECLINE_FRIEND_REQUEST_FAILURE, error } }
}