export const messageConstants = {
   
    GET_MESSAGE_SUCCESS: 'MESSAGE_GET_SUCCESS',
    GET_MESSAGE_FAILURE: 'MESSAGE_GET_FAILURE',
    GET_MESSAGE_DETAILS_SUCCESS: 'MESSAGE_DETAILS_GET_SUCCESS',
    GET_MESSAGE_DETAILS_FAILURE: 'MESSAGE_DETAILS_GET_FAILURE',
    REPLY_MESSAGE_SUCCESS: 'MESSAGE_REPLY_SUCCESS',
    REPLY_MESSAGE_FAILURE: 'MESSAGE_REPLY_FAILURE',
    SENT_MESSAGE_SUCCESS: 'MESSAGE_SENT_SUCCESS',
    SENT_MESSAGE_FAILURE: 'MESSAGE_SENT_FAILURE',
    UNREAD_MESSAGE_SUCCESS: 'MESSAGE_UNREAD_SUCCESS',
    UNREAD_MESSAGE_FAILURE: 'MESSAGE_UNREAD_FAILURE',
    THREAD_MESSAGE_SUCCESS: 'MESSAGE_THREAD_SUCCESS',
    THREAD_MESSAGE_FAILURE: 'MESSAGE_THREAD_FAILURE',
    COMPOSE_MAIL_SUCCESS: 'MAIL_COMPOSE_SUCCESS',
    COMPOSE_MAIL_FAILURE: 'MAIL_COMPOSE_FAILURE',
    GET_USERS_SUCCESS: 'USERS_GET_SUCCESS',
    GET_USERS_FAILURE: 'USERS_GET_FAILURE',
    REPLY_THREAD_SUCCESS: 'THREAD_REPLY_SUCCESS',
    REPLY_THREAD_FAILURE: 'THREAD_REPLY_FAILURE',
    CREATE_CHAT_SUCCESS: 'CHAT_CREATE_SUCCESS',
    CREATE_CHAT_FAILURE: 'CHAT_CREATE_FAILURE',
    SEND_CHAT_SUCCESS: 'CHAT_SEND_SUCCESS',
    SEND_CHAT_FAILURE: 'CHAT_SEND_FAILURE',
    GET_CHAT_DETAILS_SUCCESS: 'CHAT_DETAILS_GET_SUCCESS',
    GET_CHAT_DETAILS_FAILURE: 'CHAT_DETAILS_GET_FAILURE',
    GET_CHATS_ALL_SUCCESS: 'CHATS_ALL_GET_SUCCESS',
    GET_CHATS_ALL_FAILURE: 'CHATS_ALL_GET_FAILURE',
    GET_CHAT_USER_SUCCESS: 'CHAT_USER_GET_SUCCESS',
    GET_CHAT_USER_FAILURE: 'CHAT_USER_GET_FAILURE'
};