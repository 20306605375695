import {financialConstants } from '../_constants';
import { financialService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const financialActions = {
   gift,
   request,
   statistics,
   getpendings,
   acceptGift,
   declineGift,
    //confirm,
   withdrawal,
   yamard,
   mobilemoneydepo,
   promoDeposit
};

//this function is to create specialbusiness request
function gift(amount, username){
    return dispatch => {
        financialService.gift(amount,username)
            .then(
                datas => { 
                   
                    if(datas.isCode === 100){
                        history.push('/in/financials');
                    }
                     dispatch(success(datas.message));
                   
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(financialmessage) { return { type: financialConstants.GET_SUCCESS, financialmessage } }
    function failure(error) { return { type: financialConstants.GET_FAILURE, error } }
}

//this function is to create specialbusiness request
function request(amount, username){
    return dispatch => {
        financialService.request(amount,username)
            .then(
                datas => { 
                     dispatch(success(datas.message));
                   
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(financialmessage) { return { type: financialConstants.GET_SUCCESS, financialmessage } }
    function failure(error) { return { type: financialConstants.GET_FAILURE, error } }
}

//this function is to create specialbusiness request
function statistics(){
    return dispatch => {
        financialService.statistics()
            .then(
                datas => { 
                     dispatch(success(datas));
                   
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(statisticsdata) { return { type: financialConstants.GET_SUCCESS, statisticsdata } }
    function failure(error) { return { type: financialConstants.GET_FAILURE, error } }
}


//this function is to create specialbusiness request
function getpendings(){
    return dispatch => {
        financialService.getpendings()
            .then(
                pendings => { 
                     dispatch(success(pendings));
                   
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(pendings) { return { type: financialConstants.GET_ALL_GIFT_REQUEST_SUCCESS, pendings } }
    function failure(error) { return { type: financialConstants.GET_ALL_GIFT_REQUEST_FAILURE, error } }
}

//this function is to accept a gift request
function acceptGift(idGift){
    return dispatch => {
        financialService.acceptGift(idGift)
            .then(
                datas => { 
                    dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(response) { return { type: financialConstants.ACCEPT_GIFT_SUCCESS, response } }
    function failure(error) { return { type: financialConstants.ACCEPT_GIFT_FAILURE, error } }
}

//this function is to decline a gift request
function declineGift(idGift){
    return dispatch => {
        financialService.declineGift(idGift)
            .then(
                datas => { 
                    dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(response) { return { type: financialConstants.DECLINE_GIFT_SUCCESS, response } }
    function failure(error) { return { type: financialConstants.DECLINE_GIFT_FAILURE, error } }
}

/*
//this function is to create specialbusiness request
function confirm(idGift){
    return dispatch => {
        financialService.confirm(idGift)
            .then(
                datas => { 
                     dispatch(success(datas));
                   
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(pendings) { return { type: financialConstants.GET_SUCCESS, pendings } }
    function failure(error) { return { type: financialConstants.GET_FAILURE, error } }
}
*/

//this function is to create specialbusiness request
function yamard(info){
    return dispatch => {
        financialService.yamard(info)
            .then(
                datas => { 
                     dispatch(success(datas.message));
                   
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(financialmessage) { return { type: financialConstants.GET_SUCCESS, financialmessage } }
    function failure(error) { return { type: financialConstants.GET_FAILURE, error } }
}

//this function is to create specialbusiness request
function withdrawal(phoneNumber, amount, password,  method){
    return dispatch => {
        financialService.withdrawal(phoneNumber, amount, password, method)
            .then(
                datas => { 
                     dispatch(success(datas.message));
                   
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(financialmessage) { return { type: financialConstants.GET_SUCCESS, financialmessage } }
    function failure(error) { return { type: financialConstants.GET_FAILURE, error } }
}

//this function is to cashin using mobilemoney
function mobilemoneydepo(amount, phoneNumber, method){
    return dispatch => {
        financialService.mobilemoneydepo(amount,phoneNumber,method)
            .then(
                datas => { 
                    if(method === 'Orange Money' && datas.isCode === 201){
                        //get datas.results for orange money and redirect the webpage to the url found inside
                         //history.push(datas.results.payment_url);
                          window.location.replace(datas.results.payment_url)
                    }
                    dispatch(success(datas.message));
                   
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(financialmessage) { return { type: financialConstants.GET_SUCCESS, financialmessage } }
    function failure(error) { return { type: financialConstants.GET_FAILURE, error } }
}

function promoDeposit(promocode){
    return dispatch => {
        financialService.promoDeposit(promocode)
            .then(
                datas => { 
                     dispatch(success(datas.message));
                   
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(financialmessage) { return { type: financialConstants.GET_SUCCESS, financialmessage } }
    function failure(error) { return { type: financialConstants.GET_FAILURE, error } }
}
