import React, { Component } from "react";
import {
  NavItem,
  Fa,
  NavbarNav,
  NavLink,
  Badge,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBContainer,
  MDBBtn,
} from "mdbreact";
//import { Trans} from '@lingui/macro';
import { history } from "../../_helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userActions } from "../../_actions";
import Notifications from "./../../Notifications/Notifications";

class DashboardNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: {
        dashClass: "user-nav-icon",
        messageClass: "user-nav-icon ",
        requestClass: "user-nav-icon ",
        notifClass: "user-nav-icon ",
        finClass: "user-nav-icon ",
        musicClass: " user-nav-icon ",
        eventClass: " user-nav-icon ",
      },
    };
    this.updateUnreadNotifCount = this.updateUnreadNotifCount.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  updateUnreadNotifCount() {
    //dispatch action to read notifcations of the current user
    //const {dispatch } = this.props;
    this.props.getNotifications();
  }

  onClick(value) {
    if (value === "dashboard") {
      this.setState({
        nav: {
          dashClass: "navActive user-nav-icon ",
          messageClass: "user-nav-icon ",
          requestClass: "user-nav-icon ",
          notifClass: "user-nav-icon ",
          finClass: "user-nav-icon ",
          musicClass: " user-nav-icon ",
          eventClass: " user-nav-icon ",
        },
      });
    } else if (value === "messages") {
      this.setState({
        nav: {
          messageClass: "navActive user-nav-icon ",
          dashClass: "user-nav-icon ",
          requestClass: "user-nav-icon ",
          notifClass: "user-nav-icon ",
          finClass: "user-nav-icon ",
          musicClass: " user-nav-icon ",
          eventClass: " user-nav-icon ",
        },
      });
    } else if (value === "requests") {
      this.setState({
        nav: {
          messageClass: "user-nav-icon ",
          dashClass: "user-nav-icon ",
          requestClass: "navActive user-nav-icon ",
          notifClass: "user-nav-icon ",
          finClass: "user-nav-icon ",
          musicClass: " user-nav-icon ",
          eventClass: " user-nav-icon ",
        },
      });
    } else if (value === "notifications") {
      this.setState({
        nav: {
          messageClass: "user-nav-icon ",
          dashClass: "user-nav-icon ",
          requestClass: "user-nav-icon ",
          notifClass: "navActive user-nav-icon ",
          finClass: "user-nav-icon ",
          musicClass: " user-nav-icon ",
          eventClass: " user-nav-icon ",
        },
      });
    } else if (value === "financials") {
      this.setState({
        nav: {
          messageClass: "user-nav-icon ",
          dashClass: "user-nav-icon ",
          requestClass: "user-nav-icon ",
          notifClass: "user-nav-icon ",
          musicClass: " user-nav-icon ",
          eventClass: " user-nav-icon ",
          finClass: "navActive user-nav-icon ",
        },
      });
    } else if (value === "musics") {
      this.setState({
        nav: {
          messageClass: "user-nav-icon ",
          dashClass: "user-nav-icon ",
          requestClass: "user-nav-icon ",
          notifClass: "user-nav-icon ",
          finClass: "user-nav-icon ",
          musicClass: "navActive user-nav-icon ",
          eventClass: " user-nav-icon ",
        },
      });
    } else if (value === "events") {
      this.setState({
        nav: {
          messageClass: "user-nav-icon ",
          dashClass: "user-nav-icon ",
          requestClass: "user-nav-icon ",
          notifClass: "user-nav-icon ",
          finClass: " user-nav-icon ",
          musicClass: " user-nav-icon ",
          eventClass: "navActive user-nav-icon ",
        },
      });
    }
  }

  render() {
    const { nav } = this.state;

    const { navbadgesinfo } = this.props;
    return (
      <NavbarNav right>
        <NavItem className={nav.musicClass}>
          <NavLink
            to="/musics/home"
            onClick={() => {
              this.onClick("musics");
              history.push("/musics/home");
            }}
            title="Music area"
          >
            <span className="noBadge borderNav">
              <Fa icon="music" size="lg" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem className={nav.eventClass}>
          <NavLink
            to="/events"
            onClick={() => {
              this.onClick("events");
              history.push("/events");
            }}
            title="Events area"
          >
            <span className="noBadge borderNav">
              <Fa icon="paw" size="lg" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem className={nav.dashClass}>
          <NavLink
            to="/dashboard"
            onClick={() => {
              this.onClick("dashboard");
              history.push("/dashboard");
            }}
            title="Home"
          >
            <span className="noBadge borderNav">
              <Fa icon="home" size="lg" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem className={nav.finClass}>
          <NavLink
            to="/in/financials"
            onClick={() => {
              this.onClick("financials");
              history.push("/in/financials");
            }}
            title="Financials"
            className="iconWithMarginTop"
          >
            <span className="noBadge">
              <Fa icon="credit-card" size="lg" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem className={nav.messageClass}>
          <NavLink
            to="/dashboard/messages"
            onClick={() => {
              this.onClick("messages");
              history.push("/dashboard/messages");
            }}
            title="Messages and chats"
            className="iconWithMarginTop"
          >
            <span>
              {navbadgesinfo && navbadgesinfo.countMails !== 0 ? (
                <span>
                  <div className="navBadge">
                    <Badge color="pink" pill>
                      {navbadgesinfo.countMails}
                    </Badge>
                  </div>
                  <span className="site-second-color noBadge">
                    <Fa icon="comments-o" size="lg" />
                  </span>
                </span>
              ) : (
                <span className="site-second-color noBadge">
                  <Fa icon="comments-o" size="lg" />
                </span>
              )}
            </span>
          </NavLink>
        </NavItem>

        <NavItem className={nav.requestClass}>
          <NavLink
            to="/dashboard/requests"
            onClick={() => {
              this.onClick("requests");
              history.push("/dashboard/requests");
            }}
            title="See your requests"
            className="iconWithMarginTop"
          >
            <span>
              {navbadgesinfo && navbadgesinfo.countRequests !== 0 ? (
                <span>
                  <div className="navBadge">
                    <Badge color="pink" pill>
                      {navbadgesinfo.countRequests}
                    </Badge>
                  </div>
                  <span className="noBadge">
                    <Fa icon="address-book-o" size="lg" />
                  </span>
                </span>
              ) : (
                <span className="noPadding noBadge">
                  <Fa icon="address-book-o" size="lg" />
                </span>
              )}
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <MDBContainer>
            <div style={{ display: "flex" }} className="m-0 p-0">
              <MDBPopover placement="bottom" popover clickable id="popper3">
                <MDBBtn className={"btn btn-link notif-nav-link iconWithMarginTop"}>
                  {navbadgesinfo && navbadgesinfo.countNotifcations !== 0 ? (
                    <span>
                      <div className="navBadge">
                        <Badge color="pink" pill>
                          {navbadgesinfo.countNotifcations}
                        </Badge>
                      </div>
                      <span className="site-second-color noBadge">
                        <Fa icon="bell-o" size="lg" />
                      </span>
                    </span>
                  ) : (
                    <span className="site-second-color noBadge noPadding ">
                      <Fa icon="bell-o" size="lg" />
                      <span className="site-second-color"></span>
                    </span>
                  )}
                </MDBBtn>
                <div>
                  <MDBPopoverHeader>All Notifications</MDBPopoverHeader>
                  <MDBPopoverBody className="px-0 notifications">
                    <Notifications
                      updateUnreadNotifCount={this.updateUnreadNotifCount}
                    />
                  </MDBPopoverBody>
                </div>
              </MDBPopover>
            </div>
          </MDBContainer>
          {/*<NavLink to="/dashboard/notifications" title="Notifications" onClick={() => (this.onClick('notifications'),history.push('/dashboard/notifications'))} ><Fa icon="bell-o" size="lg"/><Trans> Notifications</Trans></NavLink>*/}
        </NavItem>
      </NavbarNav>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

/*
function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ ...userActions });
    return { ...actions, dispatch };
}*/

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNav);
