export const musicConstants = {
   
    GET_SUCCESS: 'MUSIC_GET_SUCCESS',
    GET_FAILURE: 'MUSIC_GET_FAILURE',
    GET_ITEM_SUCCESS: 'MUSIC_ITEM_GET_SUCCESS',
    GET_ITEM_FAILURE: 'MUSIC_ITEM_GET_FAILURE',
    MESSAGE_ONLY:'MESSAGE_ONLY',
    GET_PLAYLIST_SUCCESS:'GET_PLAYLIST_SUCCESS',
    GET_PLAYLIST_FAILURE:'GET_PLAYLIST_FAILURE',
    GET_PLAYLISTRELEASES_SUCCESS:'GET_PLAYLISTRELEASES_SUCCESS',
    GET_FOOTER_ITEM_SUCCESS:'GET_FOOTER_ITEM_SUCCESS',
    GET_JOINED_SUCCESS:'GET_JOINED_SUCCESS',
    GET_JOINED_FAILURE:'GET_JOINED_FAILURE',
    GET_GENRES_SUCCESS:'GET_GENRES_SUCCESS',
    GET_PLAYLIST_JOINED_SUCCESS:'GET_PLAYLIST_JOINED_SUCCESS',
    GET_CHARTS_SUCCESS:'GET_CHARTS_SUCCESS',
    GET_CHARTS_ITEM_SUCCESS:'GET_CHARTS_ITEM_SUCCESS',
    TOGGLE_SHOW_RELEASE_SALES_SUCCESS:'TOGGLE_SHOW_RELEASE_SALES_SUCCESS',
    TOGGLE_SHOW_RELEASE_SALES_FAILURE:'TOGGLE_SHOW_RELEASE_SALES_FAILURE',
    GET_MANAGE_SUCCESS:'GET_MANAGE_SUCCESS',
    GET_MANAGE_FAILURE:'GET_MANAGE_FAILURE'
};