import { relationsConstants } from '../_constants';
// gets all friends of a user
export function allfriendtion(state = {}, action) {
    switch (action.type) {
        case relationsConstants.GET_ALL_FRIENDS_SUCCESS:
            return {
                allFriendState: true,
                users: action.users
            };
        case relationsConstants.GET_ALL_FRIENDS_FAILURE:
            return {
                allFriendState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// gets all friend requests
export function getallfriendrequestion(state = {}, action) {
    switch (action.type) {
        case relationsConstants.GET_ALL_FRIEND_REQUEST_SUCCESS:
            return {
                allFriendState: true,
                users: action.users
            };
        case relationsConstants.GET_ALL_FRIEND_REQUEST_FAILURE:
            return {
                allFriendState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// accept friend requests
export function acceptfriendrequestion(state = {}, action) {
    switch (action.type) {
        case relationsConstants.ACCEPT_FRIEND_REQUEST_SUCCESS:
            return {
                allFriendState: true,
                response: action.response
            };
        case relationsConstants.ACCEPT_FRIEND_REQUEST_FAILURE:
            return {
                allFriendState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// decline friend requests
export function declinefriendrequestion(state = {}, action) {
    switch (action.type) {
        case relationsConstants.DECLINE_FRIEND_REQUEST_SUCCESS:
            return {
                allFriendState: true,
                response: action.response
            };
        case relationsConstants.DECLINE_FRIEND_REQUEST_FAILURE:
            return {
                allFriendState: false,
                error:action.error 
            };
        default:
        return state
    }
}