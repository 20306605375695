import { apiConstants } from '../_constants';
//import { authHeader } from '../_helpers';

let user = JSON.parse(localStorage.getItem('user'));

export const eventService = {
    rightnav,
    topnav,
    geteventItem,
    likeevent,
    getreplycomments,
    replyeventcomment,
    commentevent,
    likecomment,
    purchase,
    createevent,
    deleteevent,
    allattending,
    markticket,
    updateevent,
    purchaseunauthtickets

};

//this is to handle the request of all items submitted through Nav bar eg Latest, trending, singgle
function rightnav(linkItem, username){
	const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
             userId: username
        })
    };
    if(user){
        return fetch(apiConstants.API_EVENT_URL+linkItem+'?access-token='+user.auth_key,requestOptions)
            .then(handleResponse)
            .then(json => {
                return json;
        });
    }
    else{
        return fetch(apiConstants.API_EVENT_URL+linkItem, requestOptions)
            .then(handleResponse)
            .then(json => {
                return json;
        });
    }
    
}

//this is to handle the request of all items submitted through Nav bar eg Latest, trending, singgle
function topnav(item){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    if(user){
        return fetch(apiConstants.API_EVENT_URL+item+'?access-token='+user.auth_key,requestOptions)
            .then(handleResponse)
            .then(json => {
                return json;
        });
    }
    else{
        return fetch(apiConstants.API_EVENT_URL+item,requestOptions)
            .then(handleResponse)
            .then(json => {
                return json;
        });
    }
}

//this is to handle the request to get the detials of an event
function geteventItem(eventId){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
              eventId
        })
    };
    if(user){
        return fetch(apiConstants.API_EVENT_URL+'getdetails?access-token='+user.auth_key,requestOptions)
            .then(handleResponse)
            .then(json => {
                return json;
        });
    }
    else{
        return fetch(apiConstants.API_EVENT_URL+'getnotlogindetails',requestOptions)
            .then(handleResponse)
            .then(json => {
                return json;
        });
    }
}

// get the replies to a comment
function getreplycomments(parentId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ parentId })
    };
    return fetch(apiConstants.API_EVENT_URL+'getreplycomments?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//like a post
function likeevent(eventId, liked) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({ eventId, liked })
    };
    return fetch(apiConstants.API_EVENT_URL+'comment?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//reply to a comment
function replyeventcomment(parentId, comment) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ parentId, comment })
    };
    return fetch(apiConstants.API_EVENT_URL+'replycomment?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//reply to a comment
function commentevent(eventId, comment) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ eventId, comment })
    };
    return fetch(apiConstants.API_EVENT_URL+'comment?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//like a comment
function likecomment(parentId, liked) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({ parentId, liked })
    };
    return fetch(apiConstants.API_EVENT_URL+'replycomment?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this is to purchase the tickets of an event
//like a comment
function purchase(number, type, eventId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({ number, type, eventId })
    };
    return fetch(apiConstants.API_EVENT_URL+'purchase?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}


//delete a release
function createevent(info) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify(info)
    };
    return fetch(apiConstants.API_EVENT_URL+'createevent?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}


//delete a event
function deleteevent(eventId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({eventId})
    };
    return fetch(apiConstants.API_EVENT_URL+'deleteevent?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//get all those attending the event
function allattending(eventId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({eventId})
    };
    return fetch(apiConstants.API_EVENT_URL+'allattending?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//get all those attending the event
function markticket(ticketId,ticketvalue) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({ticketId, ticketvalue})
    };
    return fetch(apiConstants.API_EVENT_URL+'markticket?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function updateevent(info) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify(info)
    };
    return fetch(apiConstants.API_EVENT_URL+'updateinfo?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}


//this is to handle song purchase before login
function purchaseunauthtickets(urlname, amount, name, phoneNum, emailAddress, method, ticketnumber, tickettype){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            urlname, 
            amount,
            name,
            phoneNum, 
            emailAddress,
            method,
            ticketnumber,
            tickettype
        })
    };
    return fetch(apiConstants.API_EXTERNALTHINGS_URL+'payticketsnotloginmomo',requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

