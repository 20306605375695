import { adminConstants } from '../_constants';

export function getpendingaccounts(state = {}, action) {
    switch (action.type) {
        case adminConstants.GET_PENDING_ACCOUNTS_SUCCESS:
            return {
                pendingAccountState: true,
                accounts: action.accounts
            };
        case adminConstants.GET_PENDING_ACCOUNTS_FAILURE:
            return {
                pendingAccountState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function adminmessages(state = {}, action) {
    switch (action.type) {
        case adminConstants.GET_MESSAGE_SUCCESS:
            return {
                adminMessage: true,
                message: action.message
            };
        case adminConstants.GET_MESSAGE_FAILURE:
            return {
                adminMessage: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function activateAccount(state = {}, action) {
    switch (action.type) {
        case adminConstants.ACCOUNTS_ACTIVATE_SUCCESS:
            return {
                pendingAccountState: true,
                response: action.response
            };
        case adminConstants.ACCOUNTS_ACTIVATE_FAILURE:
            return {
                pendingAccountState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function getpendingwithdrawals(state = {}, action) {
    switch (action.type) {
        case adminConstants.GET_PENDING_WITHDRAWALS_SUCCESS:
            return {
                pendingWithdrawalState: true,
                accounts: action.accounts
            };
        case adminConstants.GET_PENDING_WITHDRAWALS_FAILURE:
            return {
                pendingWithdrawalState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function acceptwithdrawal(state = {}, action) {
    switch (action.type) {
        case adminConstants.ACCEPT_WITHDRAWALS_SUCCESS:
            return {
                acceptWithdrawalState: true,
                response: action.response
            };
        case adminConstants.ACCEPT_WITHDRAWALS_FAILURE:
            return {
                acceptWithdrawalState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function rejectwithdrawal(state = {}, action) {
    switch (action.type) {
        case adminConstants.REJECT_WITHDRAWALS_SUCCESS:
            return {
                rejectWithdrawalState: true,
                response: action.response
            };
        case adminConstants.REJECT_WITHDRAWALS_FAILURE:
            return {
                rejectWithdrawalState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function getcompanystaff(state = {}, action) {
    switch (action.type) {
        case adminConstants.GET_COMPANY_STAFF_SUCCESS:
            return {
                companyStaffState: true,
                members: action.members
            };
        case adminConstants.GET_COMPANY_STAFF_FAILURE:
            return {
                companyStaffState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function gettrendings(state = {}, action) {
    switch (action.type) {
        case adminConstants.GET_TRENDING_SUCCESS:
            return {
                trendingsState: true,
                trendings: action.trendings
            };
        case adminConstants.GET_TRENDING_FAILURE:
            return {
                trendingsState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function getblockedusers(state = {}, action) {
    switch (action.type) {
        case adminConstants.GET_BLOCKED_USERS_SUCCESS:
            return {
                blockedUsersState: true,
                busers: action.busers
            };
        case adminConstants.GET_BLOCKED_USERS_FAILURE:
            return {
                blockedUsersState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function unblockuser(state = {}, action) {
    switch (action.type) {
        case adminConstants.UNBLOCK_USER_SUCCESS:
            return {
                unblockUserState: true,
                response: action.response
            };
        case adminConstants.UNBLOCK_USER_FAILURE:
            return {
                unblockUserState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function getbooks(state = {}, action) {
    switch (action.type) {
        case adminConstants.GET_BOOKS_SUCCESS:
            return {
                booksUserState: true,
                books: action.books
            };
        case adminConstants.GET_BOOKS_FAILURE:
            return {
                booksUserState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function savebook(state = {}, action) {
    switch (action.type) {
        case adminConstants.SAVE_BOOK_SUCCESS:
            return {
                bookSaveState: true,
                response: action.response
            };
        case adminConstants.SAVE_BOOK_FAILURE:
            return {
                bookSaveState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function deletebook(state = {}, action) {
    switch (action.type) {
        case adminConstants.DELETE_BOOK_SUCCESS:
            return {
                bookDeleteState: true,
                response: action.response
            };
        case adminConstants.DELETE_BOOK_FAILURE:
            return {
                bookDeleteState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function getreleases(state = {}, action) {
    switch (action.type) {
        case adminConstants.GET_RELEASES_SUCCESS:
            return {
                releasesState: true,
                releases: action.releases
            };
        case adminConstants.GET_RELEASES_FAILURE:
            return {
                releasesState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function deleteresponse(state = {}, action) {
    switch (action.type) {
        case adminConstants.DELETE_SUCCESS:
            return {
                deleteState: true,
                deleteresponse: action.response
            };
        case adminConstants.DELETE_FAILURE:
            return {
                deleteState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function getallusers(state = {}, action) {
    switch (action.type) {
        case adminConstants.GET_ALL_USERS_SUCCESS:
            return {
                usersState: true,
                users: action.users
            };
        case adminConstants.GET_ALL_USERS_FAILURE:
            return {
                usersState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function createuser(state = {}, action) {
    switch (action.type) {
        case adminConstants.CREATE_USER_SUCCESS:
            return {
                userCreateState: true,
                response: action.response
            };
        case adminConstants.CREATE_USER_FAILURE:
            return {
                userCreateState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function createartist(state = {}, action) {
    switch (action.type) {
        case adminConstants.CREATE_USER_SUCCESS:
            return {
                userCreateState: true,
                artistresponse: action.artistresponse
            };
        case adminConstants.CREATE_USER_FAILURE:
            return {
                userCreateState: false,
                error:action.error 
            };
        default:
        return state
    }
}

//update user or artist
export function editua(state = {}, action) {
    switch (action.type) {
        case adminConstants.EDIT_USER_SUCCESS:
            return {
                userEditState: true,
                response: action.response
            };
        case adminConstants.EDIT_USER_FAILURE:
            return {
                userEditState: false,
                error:action.error 
            };
        default:
        return state
    }
}

//delete trend
export function deletetrend(state = {}, action) {
    switch (action.type) {
        case adminConstants.DELETE_TREND_SUCCESS:
            return {
                deleteTrendState: true,
                response: action.response
            };
        case adminConstants.DELETE_TREND_FAILURE:
            return {
                deleteTrendState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function changestatus(state = {}, action) {
    switch (action.type) {
        case adminConstants.CHANGE_STATUS_SUCCESS:
            return {
                changeStatusState: true,
                response: action.response
            };
        case adminConstants.CHANGE_STATUS_FAILURE:
            return {
                changeStatusState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function getcontactus(state = {}, action) {
    switch (action.type) {
        case adminConstants.GET_CONTACT_US_SUCCESS:
            return {
                getContactUs: true,
                contacts: action.contacts
            };
        case adminConstants.GET_CONTACT_US_FAILURE:
            return {
                getContactUs: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function deletecontactus(state = {}, action) {
    switch (action.type) {
        case adminConstants.DELETE_CONTACT_US_SUCCESS:
            return {
                deleteContactUs: true,
                response: action.response
            };
        case adminConstants.DELETE_CONTACT_US_FAILURE:
            return {
                deleteContactUs: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function replycontactus(state = {}, action) {
    switch (action.type) {
        case adminConstants.REPLY_CONTACT_US_SUCCESS:
            return {
                replyContactUs: true,
                response: action.response
            };
        case adminConstants.REPLY_CONTACT_US_FAILURE:
            return {
                replyContactUs: false,
                error:action.error 
            };
        default:
        return state
    }
}