import { apiConstants } from '../_constants';
//import { authHeader } from '../_helpers';

let user = JSON.parse(localStorage.getItem('user'));

export const musicService = {
    rightnav,
    topnav,
    getmusicItem,
    streammusic,
    buymusic,
    likemusic,
    getreplycomments,
    replymusiccomment,
    commentmusic,
    likecomment,
    deletemusic,
    createmusic,
    updatemusic,
    spackages,
    activatep,
    footerplayer,
    addtoplaylist,
    removefromlist,
    downloadSong,
    purchaseunauth,
    getplaylistreleases,
    addsubscriber,
    joinedmusics,
    getmusicgenres,
    countstreams,
    pageplaylist,
    getallplaylists,
    getplaylistsusers,
    getallcharts,
    pagechart,
    toggleshowreleasesales,
    mainmanage
};

//this is to handle the request of all items submitted through Nav bar eg Latest, trending, singgle
function rightnav(linkItem, username){
	const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            username: username
        })
    };
    if(user){
        return fetch(apiConstants.API_MUSIC_URL+linkItem+'?access-token='+user.auth_key,requestOptions)
            .then(handleResponse)
            .then(json => {
                return json;
        });
    }
    else{
        return fetch(apiConstants.API_MUSIC_URL+linkItem,requestOptions)
            .then(handleResponse)
            .then(json => {
                return json;
        });
    }
    
}

//this function is to get all the releases that belong to a playlist
function getplaylistreleases(urlname){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({
            urlname: urlname
        })
    };
    return fetch(apiConstants.API_PLAYLIST_URL+'getplaylistreleases?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

//this is to get the music genres
function getmusicgenres(){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(apiConstants.API_MUSIC_URL+'musicgenres?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

//this function is to add a subscriber to a playlist
function addsubscriber(urlname){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({
            urlname: urlname
        })
    };
    return fetch(apiConstants.API_PLAYLIST_URL+'joinsubscribers?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

//this is to handle the request of all items submitted through Nav bar eg Latest, trending, singgle
function topnav(item){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(apiConstants.API_MUSIC_URL+item+'?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

//this is to handle the request the footer player musics
function footerplayer(){
    let authKey = ''
    if(user){
       authKey =  user.auth_key;
    }
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(apiConstants.API_MUSIC_URL+'playlistmusics?access-token='+authKey,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

/**
 * This function is to get the details of a playlist. 
 */
function pageplaylist(username){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({
            username: username
        })
    };
    return fetch(apiConstants.API_MUSIC_URL+'detailedplaylist?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

//this is to handle the request of all items submitted through Nav bar eg Latest, trending, singgle
function getmusicItem(urlname){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            urlname: urlname
        })
    };
    if(user){
       return fetch(apiConstants.API_MUSIC_URL+'getdetails?access-token='+user.auth_key,requestOptions)
            .then(handleResponse)
            .then(json => {
                return json;
        }); 
    }
    else{
        return fetch(apiConstants.API_MUSIC_URL+'getnotlogindetails',requestOptions)
            .then(handleResponse)
            .then(json => {
                return json;
        });
    }
    
}

//this is to handle streaming a song
function streammusic(urlname){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            urlname: urlname
        })
    };
    return fetch(apiConstants.API_MUSIC_URL+'addstream?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

//this is to add song to playlist
function addtoplaylist(info){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info)
    };
    return fetch(apiConstants.API_MUSIC_URL+'addtolist?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

//this is to remove song from playlist
function removefromlist(info){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info)
    };
    return fetch(apiConstants.API_MUSIC_URL+'removefromlist?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}


//this is to handle streaming a song
function spackages(){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(apiConstants.API_MUSIC_URL+'getspackages?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

function activatep(idPackage) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            idPackage
         })
    };
    return fetch(apiConstants.API_MUSIC_URL+'activatestream?access-token='+user.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this is to handle song purchase
function buymusic(urlname,rtype){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            urlname: urlname,
            releaseType: rtype
        })
    };
    return fetch(apiConstants.API_MUSIC_URL+'purchase?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

//this is to handle song purchase before login
function purchaseunauth(urlname, amount, name, phoneNum, emailAddress, method){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            urlname, 
            amount,
            name,
            phoneNum, 
            emailAddress,
            method
        })
    };
    return fetch(apiConstants.API_EXTERNALTHINGS_URL+'paynotloginmomo',requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

// get the replies to a comment
function getreplycomments(parentId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ parentId })
    };
    return fetch(apiConstants.API_MUSIC_URL+'getreplycomments?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//like a post
function likemusic(releaseId, liked) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({ releaseId, liked })
    };
    return fetch(apiConstants.API_MUSIC_URL+'comment?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//reply to a comment
function replymusiccomment(parentId, comment) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ parentId, comment })
    };
    return fetch(apiConstants.API_MUSIC_URL+'replycomment?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//reply to a comment
function commentmusic(releaseId, comment) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ releaseId, comment })
    };
    return fetch(apiConstants.API_MUSIC_URL+'comment?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//like a comment
function likecomment(parentId, liked) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({ parentId, liked })
    };
    return fetch(apiConstants.API_MUSIC_URL+'replycomment?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//delete a release
function deletemusic(releaseId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({releaseId})
    };
    return fetch(apiConstants.API_MUSIC_URL+'deletemusic?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//delete a release
function updatemusic(info) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify(info)
    };
    return fetch(apiConstants.API_MUSIC_URL+'updateinfo?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//create a release
function createmusic(info) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify(info)
    };
    return fetch(apiConstants.API_MUSIC_URL+'createmusic?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}


//create a release
function downloadSong(info) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify(info)
    };
    return fetch(apiConstants.API_MUSIC_URL+'downloadmusic?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this function is to get the joined musics for release
function joinedmusics(username){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({
            username: username
        })
    };
    return fetch(apiConstants.API_MUSIC_URL+'joinedmusics?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

function countstreams(info){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify(info)
    };
    return fetch(apiConstants.API_MUSIC_URL+'countstreams?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

//this is to handle the request of all items submitted through Nav bar eg Latest, trending, singgle
function getallplaylists(){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(apiConstants.API_PLAYLIST_URL+'getjoinedplaylists?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

function getplaylistsusers(item){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(apiConstants.API_PLAYLIST_URL+item+'?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

/**
 * This is to get all the charts
*/
function getallcharts(){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(apiConstants.API_PLAYLIST_URL+'getallcharts?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

/**
 * This function is to get the details of the page
*/
function pagechart(chartUrl){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({
            chartUrl: chartUrl
        })
    };
    return fetch(apiConstants.API_PLAYLIST_URL+'getchartdetails?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}

/**
 * This function is to toggle the showing of a release sales to the public
*/
function toggleshowreleasesales(params){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
    };
    return fetch(apiConstants.API_MUSIC_URL+'toggleshowreleasesales?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
    });
}


/**
 * This function is to get all the releases for main manage page
*/
function mainmanage(){
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    return fetch(apiConstants.API_MUSIC_URL+'mainmanage?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}