import React from 'react';
import './HomeNavbar.css';
import { Fa, Button, Navbar, NavbarBrand, NavbarNav, NavLink, NavbarToggler, Collapse, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, MDBDropdownItem, Container } from 'mdbreact';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { languageActions } from '../_actions';
import { history } from '../_helpers';
import {withRouter} from 'react-router-dom';
import { apiConstants } from '../_constants';
import {Trans} from '@lingui/macro';

class HomeNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            isWideEnough: false,
            lang: '' // language selected
        };
    this.onClick = this.onClick.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    }

    onClick(value){
        this.setState({
            collapse: !this.state.collapse,
        });
        if(value=== 'home'){
            this.setState({
                    homeClass:'navActive',
                    aboutClass:false,
                    contactClass:false,
            })
        }
        else if(value=== 'about'){
            this.setState({
                    homeClass:false,
                    aboutClass:'navActive',
                    contactClass:false,
            })
        }
        else if(value==='contact'){
            this.setState({
                    homeClass:false,
                    aboutClass:false,
                    contactClass:'navActive',
            })
        }
        else{
            this.setState({
                    homeClass:false,
                    aboutClass:false,
                    contactClass:false,
            })
        }
    }

    changeLanguage(value){
        this.setState({
            lang: value
        })
        const { dispatch } = this.props;
        dispatch(languageActions.setLanguage(value));
    }

    componentDidMount(){
        const { location } = this.props;
        if(location.pathname === "/"){
            this.setState({
                homeClass:'navActive',
                contactClass:false,
                aboutClass:false,
            })
        }
        else if(location.pathname.match(/^\/about/)){
            this.setState({
                aboutClass:'navActive',
                homeClass:false,
                contactClass:false,
            })
        }
        else if(location.pathname.match(/^\/contact/)){
            this.setState({
                contactClass:'navActive',
                aboutClass:false,
                homeClass:false
            })
        }
        // set to the currently selected language
        this.setState({
            lang: this.props.language
        })
    }
    handleSubmit(event){
        event.preventDefault();
        history.push('/searching');
    }

    render() {
        const {lang} = this.state;
        return (
            <div>
                <Navbar color="bg-dark lighten-4" dark expand="xs" fixed="top">
                    <Container fluid className="">
                        <NavbarBrand href="/">
                             <img src={ apiConstants.API_IMAGES+'logos/icon.png'}
                                    className="nav-pic-md img-fluid rounded-circle" style={{marginLeft: "20px"}} alt={'logo'} />
                        </NavbarBrand>
                        <NavbarNav right>
                            <NavItem>
                                <form name="form" onClick={this.handleSubmit} className="input-group md-form form-sm form-1 pl-0 nav-search">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text grey" id="nav-search-icon"><i className="fa fa-search text-white" aria-hidden="true"></i></span>
                                    </div>
                                    <input className="form-control my-0 py-1" type="search" placeholder="Search Muzikol" aria-label="Search" />
                                </form>
                            </NavItem>
                        </NavbarNav>
                    </Container>
                </Navbar>
                <Navbar style={{backgroundColor: "#696969", marginTop: "65px", marginBottom:'40px'}} color="lighten-4" dark expand="md">
                    <Container fluid className="">
                        <Button color="white" href="/musics" title="Login to Listen to Music" size="sm"><span className="site-second-color">Listen Now!</span></Button>
                        { !this.state.isWideEnough && <NavbarToggler onClick = { this.onClick } />}
                        <Collapse isOpen = {this.state.collapse } navbar>
                            <NavbarNav>
                                <NavItem className={this.state.homeClass}>
                                    <NavLink to="/" value='/' onClick={() =>{this.onClick("home"); history.push('/')}} className="nav-link"><Trans>Home</Trans></NavLink>
                                </NavItem>
                                <NavItem className={this.state.aboutClass}>
                                    <NavLink to="/about" value='about'  onClick={()=>{this.onClick("about"); history.push('/about')}} className="nav-link"><Trans>About Us</Trans></NavLink>
                                </NavItem>
                                <NavItem className={this.state.contactClass}>
                                    <NavLink to="/contact" value="contact" onClick={()=>{this.onClick("contact"); history.push('/contact')}} className="nav-link"><Trans>Contact Us</Trans></NavLink>
                                </NavItem>
                                <NavItem>
                                    <Dropdown>
                                        <DropdownToggle nav caret><Fa icon="user-circle-o" size="lg"/></DropdownToggle>
                                        <DropdownMenu className="mt-2 nav-dropdown-menu">
                                            <DropdownItem to="/signupuser" onClick={() =>{this.onClick(); history.push('/signupuser')}} className="nav-dropdown-item navDrop"><Trans>Create An Account</Trans></DropdownItem>
                                            <MDBDropdownItem divider />
                                            <DropdownItem to="/login" onClick={() =>{this.onClick(); history.push('/login')}} className="nav-dropdown-item navDrop"><Trans>Login To Muzikol</Trans></DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </NavItem>
                            </NavbarNav>
                            
                            <Dropdown>
                                <DropdownToggle className="site-primary-color" title="Change language" nav><Fa icon="ioxhost" size="lg"/></DropdownToggle>
                                <DropdownMenu className="mt-2 nav-dropdown-menu">
                                    <DropdownItem className="nav-lang-item" value="en" onClick={()=>this.changeLanguage('en')}>
                                        <div className="lang-item-icon-div"><span className="lang-item-icon" style={{backgroundColor: "#2B93EC"}}>EN</span></div>
                                        <span className="lang-item-label">English</span>
                                        <span className="pull-right">
                                            {lang === 'en' &&
                                                <Fa icon="check" size="lg"/>
                                            }
                                        </span>
                                    </DropdownItem>
                                    <MDBDropdownItem divider />
                                    <DropdownItem className="nav-lang-item" value="fr" onClick={()=>this.changeLanguage('fr')}>
                                        <div className="lang-item-icon-div"><span className="lang-item-icon" style={{backgroundColor: "#001C98"}}>FR</span></div>
                                        <span className="lang-item-label">French</span>
                                        <span className="pull-right">
                                            {lang === 'fr' &&
                                                <Fa icon="check" size="lg"/>
                                            }
                                        </span>
                                    </DropdownItem>
                                    <MDBDropdownItem divider />
                                    <DropdownItem className="nav-lang-item" value="ar" onClick={()=>this.changeLanguage('ar')}>
                                        <div className="lang-item-icon-div"><span className="lang-item-icon" style={{backgroundColor: "#aa66cc"}}>AR</span></div>
                                        <span className="lang-item-label">Arabic</span>
                                        <span className="pull-right">
                                            {lang === 'ar' &&
                                                <Fa icon="check" size="lg"/>
                                            }
                                        </span>
                                    </DropdownItem>
                                    <MDBDropdownItem divider />
                                    <DropdownItem className="nav-lang-item" value="ce" onClick={()=>this.changeLanguage('ce')}>
                                        <div className="lang-item-icon-div"><span className="lang-item-icon" style={{backgroundColor: "#4B515D"}}>CE</span></div>
                                        <span className="lang-item-label">Chinesse</span>
                                        <span className="pull-right">
                                            {lang === 'ce' &&
                                                <Fa icon="check" size="lg"/>
                                            }
                                        </span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Collapse>
                    </Container>
                </Navbar>
            </div>
            
        );
    }
}
function mapStateToProps(state) {
   const { language} = state.languagetion;
    return {
        language
    };
}
function mapDispatchToProps(dispatch) {
   let actions = bindActionCreators({ ...languageActions });
  return { ...actions, dispatch };

}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomeNavbar))

/*
    var url = window.location;
    $('.navbar .nav').find('.active').removeClass('active');
    $('.navbar .nav li a').each(function () {
        if (this.href == url) {
            $(this).parent().addClass('active');
        }
    }); 
export default HomeNavbar;
*/