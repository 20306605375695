import { apiConstants } from '../_constants';

export const adminService = {
    getPendingAccounts,
    activateAccount,
    getPendingWithdrawals,
    acceptWithdrawal,
    rejectWithdrawal,
    getCompanyStaff,
    getTrendings,
    getBlockedUsers,
    unBlockUser,
    getBooks,
    saveBook,
    deleteBook,
    getReleases,
    deleteRelease,
    getAllUsers,
    createTester,
    deleteTester,
    resetpass,
    deactivateAccount,
    validateAcount,
    deleteUser,
    createUser,
    createArtist,
    editUser,
    editArtist,
    deleteTrend,
    changeTrendStatus,
    changeActivationStatus,
    getContactUs,
    deleteContactUs,
    replyContactUs,
};

// create a tester
function resetpass(info){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info )
    };
    return fetch(apiConstants.API_ADMIN_URL+'passwordreset?access-token='+user.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}

// create a tester
function validateAcount(user, artist){
    let currentuser = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user, artist )
    };
    return fetch(apiConstants.API_ADMIN_URL+'artistverification?access-token='+currentuser.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}

// create a tester
function createTester(info){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info )
    };
    return fetch(apiConstants.API_ADMINCREATE_URL+'createtester?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// create a tester
function deleteTester(idTestcodes){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id:idTestcodes} )
    };
    return fetch(apiConstants.API_ADMINDELETE_URL+'deletetester?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// get pending accounts
function getPendingAccounts(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ })
    };
    return fetch(apiConstants.API_ADMIN_URL+'getpendingaccounts?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function activateAccount(username){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username })
    };
    return fetch(apiConstants.API_ADMIN_URL+'activateaccount?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function deactivateAccount(username){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username })
    };
    return fetch(apiConstants.API_ADMIN_URL+'deactivateaccount?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function getPendingWithdrawals(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ })
    };
    return fetch(apiConstants.API_ADMIN_URL+'getpendingwithdrawal?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function acceptWithdrawal(idWithdrawal){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idWithdrawal })
    };
    return fetch(apiConstants.API_ADMIN_URL+'acceptwithdrawal?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function rejectWithdrawal(idWithdrawal){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idWithdrawal })
    };
    return fetch(apiConstants.API_ADMIN_URL+'rejectwithdrawal?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function getCompanyStaff(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ })
    };
    return fetch(apiConstants.API_ADMIN_URL+'aboutusers?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function getTrendings(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ })
    };
    return fetch(apiConstants.API_ADMIN_URL+'trendingdata?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function getBlockedUsers(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ })
    };
    return fetch(apiConstants.API_ADMIN_URL+'getblocking?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function unBlockUser(idBlock){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idBlock })
    };
    return fetch(apiConstants.API_ADMIN_URL+'unblockrelationship?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function getBooks(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ })
    };
    return fetch(apiConstants.API_ADMIN_URL+'bookstatistics?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function saveBook(bookdetails, idBook){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(idBook ? { idBook, bookdetails } : {bookdetails})
    };
    return fetch(apiConstants.API_ADMIN_URL+((idBook ? 'editbook' : 'createbook')+'?access-token=')+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function deleteBook(idBook){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({idBook})
    };
    return fetch(apiConstants.API_ADMIN_URL+'deletebook?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function getReleases(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
    };
    return fetch(apiConstants.API_ADMIN_URL+'releasestatistics?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function deleteRelease(idRelease){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({idRelease})
    };
    return fetch(apiConstants.API_ADMINDELETE_URL+'rdeleterelease?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function getAllUsers(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
    };
    return fetch(apiConstants.API_ADMIN_URL+'userstatistics?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function deleteUser(idUser){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({idUser})
    };
    return fetch(apiConstants.API_ADMINDELETE_URL+'deleteuser?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function createUser(userdata){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({username: userdata.username, name: userdata.name, email: userdata.email,
            password: userdata.password, user_type: userdata.user_type, phone_number: userdata.phone_number,
            gender: userdata.gender, nationality: userdata.nationality})
    };
    return fetch(apiConstants.API_ADMINCREATE_URL+'cuuser?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function createArtist(userdata){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({username: userdata.username, name: userdata.name, email: userdata.email,
            password: userdata.password, user_type: userdata.user_type, phone_number: userdata.phone_number,
            gender: userdata.gender, nationality: userdata.nationality, artisttype: userdata.artisttype,
            profession: userdata.profession, popularVidUrl: userdata.popularVidUrl, support_name: userdata.support_name,
            support_phone: userdata.support_phone, managerId: userdata.managerId, signed: userdata.signed
        })

    };
    return fetch(apiConstants.API_ADMINCREATE_URL+'cuartist?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function editUser(userdata){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({username: userdata.username, name: userdata.name, email: userdata.email,
            user_type: userdata.user_type, phone_number: userdata.phone_number,
            gender: userdata.gender, nationality: userdata.nationality, date_of_birth: userdata.date_of_birth,
            updateUser: userdata.username})

    };
    return fetch(apiConstants.API_ADMINCREATE_URL+'cuuser?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function editArtist(artistdata){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({User_pid: artistdata.User_pid, username: artistdata.username, name: artistdata.name, email: artistdata.email,
            user_type: artistdata.user_type, phone_number: artistdata.phone_number,
            gender: artistdata.gender, nationality: artistdata.nationality, date_of_birth: artistdata.date_of_birth, artisttype: artistdata.artisttype,
            profession: artistdata.profession, popularVidUrl: artistdata.popularVidUrl, support_name: artistdata.support_name,
            support_phone: artistdata.support_phone, managerId: artistdata.managerId, signed: artistdata.signed, updateUser: artistdata.username})

    };
    return fetch(apiConstants.API_ADMINCREATE_URL+'cuartist?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function deleteTrend(idTrend){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({idTrending: idTrend})
    };
    return fetch(apiConstants.API_ADMINDELETE_URL+'deletetrend?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function changeTrendStatus(idTrend, status){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({idTrending: idTrend, status})
    };
    return fetch(apiConstants.API_ADMIN_URL+'changetrendingstatus?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function changeActivationStatus(username, state){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({username: username, state: state})
    };
    return fetch(apiConstants.API_ADMIN_URL+'activateaccount?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function getContactUs(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
    };
    return fetch(apiConstants.API_ADMIN_URL+'getcontactus?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function deleteContactUs(idContactUs){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({idContactUs})
    };
    return fetch(apiConstants.API_ADMIN_URL+'deletecontactus?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function replyContactUs(idContactUs, message){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({idContactUs, message})
    };
    return fetch(apiConstants.API_ADMIN_URL+'replycontactus?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
