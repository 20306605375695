import { postConstants } from '../_constants';
import { postService, musicService, eventService } from '../_services';
import { alertActions } from './';

export const postActions = {
    createpost,
    commentpost,
    replycomment,
    likepost,
    likecomment,
    getposts,
    getdetails,
    getreplycomments,
    getallartists,
    getallreleases,
    getallevents,
    deletepost,
    reportpost,
    updatepost
};

function createpost(newpost, tags) {
    return dispatch => {
        postService.createpost(newpost, tags)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(response) { return { type: postConstants.POST_CREATE_SUCCESS, response } }
    function failure(error) { return { type: postConstants.POST_CREATE_FAILURE, error } }
}

function commentpost(postId, comment, itemType) {
    return dispatch => {
        if(itemType === 'release'){
            musicService.commentmusic(postId, comment)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
        else if(itemType === 'event'){
            eventService.commentevent(postId, comment)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
        else{
            postService.commentpost(postId, comment)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
        
    };

    function success() { return { type: postConstants.COMMENT_POST_SUCCESS } }
    function failure(error) { return { type: postConstants.COMMENT_POST_FAILURE, error } }
}


//reply to a comment
function replycomment(parentId, comment, itemType) {
    return dispatch => {
        if(itemType === 'release'){
            musicService.replymusiccomment(parentId, comment)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
        else if(itemType === 'event'){
            eventService.replyeventcomment(parentId, comment)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
        else{
            postService.replycomment(parentId, comment)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
        
    };

    function success() { return { type: postConstants.COMMENT_REPLY_SUCCESS } }
    function failure(error) { return { type: postConstants.COMMENT_REPLY_FAILURE, error } }
}

//like a post
function likepost(postId, liked, itemType) {
    return dispatch => {
        if(itemType === 'release'){
            musicService.likemusic(postId, liked)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
        else if(itemType === 'event'){
            eventService.likeevent(postId, liked)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
        else {
            postService.likepost(postId, liked)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
            
    };

    function success() { return { type: postConstants.LIKE_POST_SUCCESS } }
    function failure(error) { return { type: postConstants.LIKE_POST_FAILURE, error } }
}

//like a comment
function likecomment(parentId, liked, itemType) {
    return dispatch => {
        if(itemType === 'release'){
            musicService.likecomment(parentId, liked)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
        if(itemType === 'event'){
            eventService.likecomment(parentId, liked)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
        else{
            postService.likecomment(parentId, liked)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
    };

    function success() { return { type: postConstants.LIKE_COMMENT_SUCCESS } }
    function failure(error) { return { type: postConstants.LIKE_COMMENT_FAILURE, error } }
}

function getposts(limit, userId) {
    return dispatch => {
        postService.getposts(limit, userId)
            .then(
                posts => { 
                    dispatch(success(posts));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(posts) { return { type: postConstants.GET_POST_SUCCESS, posts} }
    function failure(error) { return { type: postConstants.GET_POST_FAILURE, error } }
}

function getdetails(postId) {
    return dispatch => {
        postService.getdetails(postId)
            .then(
                details => { 
                    dispatch(success(details));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(details) { return { type: postConstants.GET_POST_DETAILS_SUCCESS, details} }
    function failure(error) { return { type: postConstants.GET_POST_DETAILS_FAILURE, error } }
}

function getreplycomments(parentId, itemType) {
    return dispatch => {
        if(itemType === 'release'){
            musicService.getreplycomments(parentId)
            .then(
                replyComments => { 
                    dispatch(success(replyComments, parentId));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
        if(itemType === 'event'){
            eventService.getreplycomments(parentId)
            .then(
                replyComments => {
                    dispatch(success(replyComments, parentId));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
        else{
            postService.getreplycomments(parentId)
            .then(
                replyComments => { 
                    dispatch(success(replyComments, parentId));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        }
        
    };

    function success(replyComments, parentId) { return { type: postConstants.GET_REPLY_COMMENT_SUCCESS, replyComments, parentId}}
    function failure(error) { return { type: postConstants.GET_REPLY_COMMENT_FAILURE, error }}
}

// get all artists in the system
function getallartists() {
    return dispatch => {
        postService.getallartists()
            .then(
                artists => {
                    dispatch(success(artists));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function success(artists) { return { type: postConstants.GET_ARTISTS_SUCCESS, artists}}
    function failure(error) { return { type: postConstants.GET_ARTISTS_FAILURE, error }}
}

//get all releases in the system
function getallreleases(){
    return dispatch => {
        postService.getallreleases()
            .then(
                releases => {
                    dispatch(success(releases));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function success(releases) { return { type: postConstants.GET_RELEASES_SUCCESS, releases}}
    function failure(error) { return { type: postConstants.GET_RELEASES_FAILURE, error }}
}

//get all events in the system
function getallevents(){
    return dispatch => {
        postService.getallevents()
            .then(
                events => {
                    dispatch(success(events));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function success(events) { return { type: postConstants.GET_EVENTS_SUCCESS, events}}
    function failure(error) { return { type: postConstants.GET_EVENTS_FAILURE, error }}
}

// delete a post
function deletepost(postId) {
    return dispatch => {
        postService.deletepost(postId)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function success() { return { type: postConstants.DELETE_POST_SUCCESS}}
    function failure(error) { return { type: postConstants.DELETE_POST_FAILURE, error }}
}

// report a post
function reportpost(report, postId) {
    return dispatch => {
        postService.reportpost(report, postId)
            .then(
                data => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success() { return { type: postConstants.REPORT_POST_SUCCESS} }
    function failure(error) { return { type: postConstants.REPORT_POST_FAILURE, error } }
}

// update a post
function updatepost(newpost, tags) {
    return dispatch => {
        postService.updatepost(newpost, tags)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(response) { return { type: postConstants.UPDATE_POST_SUCCESS, response } }
    function failure(error) { return { type: postConstants.UPDATE_POST_FAILURE, error } }
}
