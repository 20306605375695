import { eventConstants, userConstants } from '../_constants';
import { eventService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const eventActions = {
  rightnav,
  topnav,
  geteventItem,
  purchase,
  createevent,
  deleteevent,
  allattending,
  markticket,
  updateevent,
  purchaseunauthtickets

};

//this function is handle all request done by the right nav component
function rightnav(linkItem, username){
    return dispatch => {
        eventService.rightnav(linkItem, username)
            .then(
                events => { 
                   dispatch(success(events));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(events) { return { type: eventConstants.GET_SUCCESS, events } }
    function failure(error) { return { type: eventConstants.GET_FAILURE, error } }
}

//this function is to handle all request coming from top
function topnav(item){
    return dispatch => {
        eventService.topnav(item)
            .then(
                events => { 
                   dispatch(success(events));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(events) { return { type: eventConstants.GET_SUCCESS, events } }
    function failure(error) { return { type: eventConstants.GET_FAILURE, error } }
}

//this function is to handle all request coming from top
function geteventItem(title){
    return dispatch => {
        eventService.geteventItem(title)
            .then(
                eventdata => { 
                   dispatch(success(eventdata.datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(eventitem) { return { type: eventConstants.GET_ITEM_SUCCESS, eventitem } }
    function failure(error) { return { type: eventConstants.GET_ITEM_FAILURE, error } }
}

//this function is to stream a song
 function purchase(number, type, eventId){
    return dispatch => {

        eventService.purchase(number, type, eventId)
            .then(
                purchase => {
                   dispatch(success(purchase.message, purchase.datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success( eventmessage, eventitem) { return { type: eventConstants.GET_ITEM_SUCCESS, eventmessage, eventitem } }
    function failure(error) { return { type: eventConstants.GET_ITEM_FAILURE, error } }
 }

 //this function is to update release data
 function createevent(info){
    return dispatch => {
        eventService.createevent(info)
            .then(
                datas => {
                    dispatch(success(datas.message, datas));
                    history.push('/events');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    history.push('/eventerror');
                }
            );
    }
    function success(eventmessage,info) { return { type: eventConstants.GET_ITEM_SUCCESS, eventmessage, info} }
    function failure(error) { return { type: eventConstants.GET_ITEM_FAILURE, error } }
 }


//this function is to delete an event
 function deleteevent(eventId){
    return dispatch => {
        eventService.deleteevent(eventId)
            .then(
                eventsdata => {
                   dispatch(success(eventsdata.message, eventsdata.deleted.datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(eventmessage, events) { return { type: eventConstants.GET_SUCCESS, eventmessage, events} }
    function failure(error) { return { type: eventConstants.GET_FAILURE, error } }
 }

 //this function is to get all those people attending an event

//this function is to delete an event
 function allattending(eventId){
    return dispatch => {
        eventService.allattending(eventId)
            .then(
                attendings => {
                   dispatch(success(attendings));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(attendings) { return { type: eventConstants.GET_SUCCESS, attendings} }
    function failure(error) { return { type: eventConstants.GET_FAILURE, error } }
 }


//this function is to delete an event
 function markticket(ticketId, ticketvalue){
    return dispatch => {
        eventService.markticket(ticketId, ticketvalue)
            .then(
                attendings => {
                   dispatch(success(attendings));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(attendings) { return { type: eventConstants.GET_SUCCESS, attendings} }
    function failure(error) { return { type: eventConstants.GET_FAILURE, error } }
 }

 //this function is to update release data
 function updateevent(info){
    return dispatch => {
        eventService.updateevent(info)
            .then(
                info => {
                   dispatch(success(info, info.message,));
                    history.push('/events');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(info, eventmessage) { return { type: eventConstants.GET_ITEM_SUCCESS, info, eventmessage} }
    function failure(error) { return { type: eventConstants.GET_ITEM_FAILURE, error } }
 }

 //this function is to stream a song
 function purchaseunauthtickets(urlname, amount, name, phoneNum, emailAddress, method, ticketnumber, tickettype){
    return dispatch => {

        eventService.purchaseunauthtickets(urlname, amount, name, phoneNum, emailAddress,method, ticketnumber, tickettype)
            .then(
                buy => {
                    /*if(method === 'Orange Money' && buy.isCode === 201){
                        //get datas.results for orange money and redirect the webpage to the url found inside
                         //history.push(datas.results.payment_url);
                          window.location.replace(buy.results.payment_url)
                    }*/
                    if(buy && buy.user && buy.newuser){
                        //login the user
                        let userType = 'Lover';
                        localStorage.setItem('lover','Lover');
                        localStorage.setItem('user', JSON.stringify(buy.user));
                        dispatch(loginsuccess(buy.user, userType));
                        window.location.reload()
                    }
                   dispatch(success(buy.message));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(eventmessage) { return { type: eventConstants.GET_ITEM_SUCCESS, eventmessage} }
    function loginsuccess(user, userType) { return { type: userConstants.LOGIN_SUCCESS, user, userType } }
    function failure(error) { return { type: eventConstants.GET_ITEM_FAILURE, error } }
}