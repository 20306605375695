import { userConstants } from '../_constants';
import { userService } from '../_services';
import { history } from '../_helpers';
import { alertActions } from './';

export const userActions = {
    login,
    testers,
    logout,
    validate,
    profileInfo,
    getUser,
    search,
    updateInfo,
    changepassword,
    changeusername,
    changeartist,
    changeprofilepic,
    getNotifications,
    navbadges,
};


function testers(code) {
    return dispatch => {
        //dispatch(request({ username }));
        userService.testers(code)
            .then(
                user => { 
                    if (user.isSuccess === 201) {
                        localStorage.setItem('tester',user.tester);
                        dispatch(alertActions.error(user.message));
                        dispatch(failure(user.message));
                        //window.location.reload()
                    }
                    else{
                        dispatch(failure(user.message));
                        dispatch(alertActions.error(user.message));
                        //window.location.reload()
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    //function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    //function parttest(testerCode) { return { type: userConstants.TEST_VALIDATE, testerCode} }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}   

function login(username, password) {
    return dispatch => {
        //dispatch(request({ username }));
        userService.login(username, password)
            .then(
                user => { 
                    
                     if (user.isSuccess === 201) {
                        let userType;
                        if(user.user.user_type === 'Lover'){
                            userType = 'Lover';
                            localStorage.setItem('lover','Lover');
                            dispatch(success(user, userType));
                            history.push('/dashboard');
                            window.location.reload()
                        }
                        else if(user.user.user_type === 'Artist'){
                            userType = 'Artist';
                            localStorage.setItem('artist','Artist');
                            dispatch(success(user, userType)); 
                                                     
                            history.push('/dashboard');
                            window.location.reload()
                        }
                          else if(user.user.user_type === 'Admin'){
                            userType = 'admin';
                            localStorage.setItem('admin','admin');
                            dispatch(success(user, userType)); 
                                                     
                            history.push('/dashboard');
                            window.location.reload()
                        }
                        else{
                            userType = 'null';
                            history.push('/login');
                            window.location.reload()
                        }
                        
                     }
                     else{
                        dispatch(failure(user.message));
                        dispatch(alertActions.error(user.message));
                     }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    //function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user, userType) { return { type: userConstants.LOGIN_SUCCESS, user, userType } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function validate(user){
    return dispatch => {
        let userType;
        if(user.user_type === 'Lover'){
            userType = 'Lover';
        }
        else if(user.user_type === 'Artist'){
            userType = 'Artist';
        }
        else{
            userType = 'null';
        }
        dispatch(success(user, userType));
        history.push('/dashboard');
    };
    function success(user, userType) { return { type: userConstants.LOGIN_SUCCESS, user, userType } }
}

function logout() {
    userService.logout();
    //history.push('/login');
    window.location.reload()
    return { type: userConstants.LOGOUT };

}

//this function is to get data for the profile display
function profileInfo(username){
    return dispatch => {
        userService.profileInfo(username)
           .then(
               info => {    
                 dispatch(success(info));
                return info;
               },
               error => {
                   dispatch(failure(error.toString()));
                   dispatch(alertActions.error(error.toString()));
               }
        );
   }
    function success(info) { return { type: userConstants.PROFILE_SUCCESS, info} }
    function failure(error) { return { type: userConstants.PROFILE_FAILURE, error } }
}

//this function is to get a specific user
function getUser(username){
    return dispatch => {
        userService.getUser(username)
           .then(
                user => {
                    dispatch(success(user));
               },
               error => {
                   dispatch(failure(error.toString()));
                   dispatch(alertActions.error(error.toString()));
               }
        );
   }
    function success(user) { return { type: userConstants.GET_USER_SUCCESS, user} }
    function failure(error) { return { type: userConstants.GET_USER_FAILURE, error } }
}
//this function is to get data for the profile display
function search(svalue,type){

    return dispatch => {
            if(type === 'Events'){
                userService.searchevents(svalue)
                    .then(
                       info => {    
                         dispatch(success(info));               
                        return info;
                       },
                        error => {
                           dispatch(failure(error.toString()));
                           dispatch(alertActions.error(error.toString()));
                       }
                    );
                    function success(searchvents) { return { type: userConstants.RELATIONS_SUCCESS, searchvents} }
                    function failure(error) { return { type: userConstants.RELATIONS_FAILURE, error } }
                }
            else if(type === 'Users'){
                userService.searchusers(svalue)
                    .then(
                       info => {    
                         dispatch(success(info));               
                        return info;
                       },
                       error => {
                       dispatch(failure(error.toString()));
                       dispatch(alertActions.error(error.toString()));
                   }
                );
                function success(searchusers) { return { type: userConstants.RELATIONS_SUCCESS, searchusers} }
                function failure(error) { return { type: userConstants.RELATIONS_FAILURE, error } } 
            }
            else if(type === 'Musics'){
                userService.searchmusics(svalue)
                    .then(
                       info => {    
                         dispatch(success(info));               
                        return info;
                       },
                       error => {
                       dispatch(failure(error.toString()));
                       dispatch(alertActions.error(error.toString()));
                   }           
                );
                function success(searchmusics) { return { type: userConstants.RELATIONS_SUCCESS, searchmusics} }
                function failure(error) { return { type: userConstants.RELATIONS_FAILURE, error } }
            }
            else{
                userService.searchusers(svalue)
                .then(
                   info => {    
                     dispatch(success(info));               
                    return info;
                   },
                   error => {
                       dispatch(failure(error.toString()));
                       dispatch(alertActions.error(error.toString()));
                   }
                );
                function success(searchusers) { return { type: userConstants.RELATIONS_SUCCESS, searchusers} }
                function failure(error) { return { type: userConstants.RELATIONS_FAILURE, error } } 
            }
        }
    
   }
//this function is to get a specific user
function updateInfo(info){
    return dispatch => {
        userService.updateInfo(info)
           .then(
                info => {
                    if(info.isCode === 201){
                      localStorage.setItem('user', JSON.stringify(info.user));
                      dispatch(success(info.message, info.user));
                    }
                    else{
                      dispatch(partial(info.message));
                    }
               },
               error => {
                   dispatch(failure(error.toString()));
                   dispatch(alertActions.error(error.toString()));
               }
        );
   }
    function success(updatemessage, user) { return { type: userConstants.USER_SUCCESS, updatemessage, user} }
    function partial(updatemessage) { return { type: userConstants.USER_PARTIAL_SUCCESS, updatemessage} }
    function failure(error) { return { type: userConstants.USER_FAILURE, error } }
}


//this function is to get a specific user
function changepassword(password, newpass){
    return dispatch => {
        userService.changepassword(password, newpass)
           .then(
                info => {
                    if(info.isCode === 201){
                      localStorage.setItem('user', JSON.stringify(info.user));
                      dispatch(success(info.message, info.user));
                    }
                    else{
                      dispatch(partial(info.message));
                    }
               },
               error => {
                   dispatch(failure(error.toString()));
                   dispatch(alertActions.error(error.toString()));
               }
        );
   }
  function partial(updatemessage) { return { type: userConstants.USER_PARTIAL_SUCCESS, updatemessage} }
  function success(updatemessage) { return { type: userConstants.USER_PARTIAL_SUCCESS, updatemessage} }
  function failure(error) { return { type: userConstants.USER_FAILURE, error } }
}

//this function is to get a specific user
function changeprofilepic(){
    return dispatch => {
        userService.changeprofilepic()
           .then(
                info => {
                    if(info.isCode === 201){
                     
                      //history.push('/settings');
                      localStorage.setItem('user', JSON.stringify(info.user));
                      dispatch(success(info.message, info.user));
                      window.location.reload()
                    }
                    else{
                      dispatch(partial(info.message));
                    }
               },
                error => {
                   dispatch(failure(error.toString()));
                   dispatch(alertActions.error(error.toString()));
                }
            );
      }
      function success(updatemessage, user) { return { type: userConstants.USER_SUCCESS, updatemessage, user} }
    function partial(updatemessage) { return { type: userConstants.USER_PARTIAL_SUCCESS, updatemessage} }
    function failure(error) { return { type: userConstants.USER_FAILURE, error } }
  }

//this function is to get a specific user
function changeusername(info){
    return dispatch => {
        userService.changeusername(info)
           .then(
                info => { 
                    if(info.isCode === 201){
                     
                      dispatch(success(info.message, info.user));
                    }
                    else{
                      dispatch(partial(info.message));
                    }
                },
                error => {
                   dispatch(failure(error.toString()));
                   dispatch(alertActions.error(error.toString()));
                }
            );
      }
      function success(updatemessage, user) { return { type: userConstants.USER_SUCCESS, updatemessage, user} }
    function partial(updatemessage) { return { type: userConstants.USER_PARTIAL_SUCCESS, updatemessage} }
    function failure(error) { return { type: userConstants.USER_FAILURE, error } }
  }

function changeartist(info){
    return dispatch => {
        userService.changeartist(info)
           .then(
                info => { 
                      dispatch(partial(info.message));
                },
                error => {
                   dispatch(failure(error.toString()));
                   dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function partial(updatemessage) { return { type: userConstants.USER_PARTIAL_SUCCESS, updatemessage} }
    function failure(error) { return { type: userConstants.USER_FAILURE, error } }

  }
  
// get user's notification  
function getNotifications(){
    return dispatch => {
        userService.getNotifications()
           .then(
                info => { 
                      dispatch(success(info));
                },
                error => {
                   dispatch(failure(error.toString()));
                   dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(notifications) { return { type: userConstants.GET_USER_NOTIFICATIONS_SUCCESS, notifications} }
    function failure(error) { return { type: userConstants.GET_USER_NOTIFICATIONS_FAILURE, error } }
  }


// get user's notification  
function navbadges(){
    return dispatch => {
        userService.navbadges()
           .then(
                info => { 
                      dispatch(success(info));
                },
                error => {
                   dispatch(failure(error.toString()));
                   dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(navbadgesinfo) { return { type: userConstants.USER_BADGES_SUCCESS, navbadgesinfo} }
    function failure(error) { return { type: userConstants.USER_NOTIFICATIONS_FAILURE, error } }
  }
