import { musicConstants,financialConstants,userConstants } from '../_constants';
import { musicService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const musicActions = {
  rightnav,
  topnav,
  getmusicItem,
  streammusic,
  buymusic,
  deletemusic,
  updatemusic,
  createmusic,
  getspackages,
  activatep,
  footerplayer,
  addtoplaylist,
  removefromlist,
  downloadSong,
  purchaseunauth,
  allplaylist,
  getplaylistreleases,
  addsubscriber,
  streammusicPlaylist,
  buymusicPlaylist,
  joinedmusics,
  getmusicgenres,
  countstreams,
  pageplaylist,
  getallplaylists,
  getplaylistsusers,
  getallcharts,
  pagechart,
  toggleshowreleasesales,
  mainmanage
};

//this function is handle all request done by the right nav component
function rightnav(linkItem, username){
    return dispatch => {
        musicService.rightnav(linkItem, username)
            .then(
                music => { 
                    if(typeof music === 'object' && music !== null){
                        dispatch(success(music.data));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(music) { return { type: musicConstants.GET_SUCCESS, music } }
    function failure(error) { return { type: musicConstants.GET_FAILURE, error } }
}


//this function is to get all the playlist wegot
function allplaylist(){
    return dispatch => {
        musicService.allplaylist()
            .then(
                playlists => { 
                   dispatch(success(playlists.allplaylists,));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(playlists) { return { type: musicConstants.GET_PLAYLIST_SUCCESS, playlists} }
    function failure(error) { return { type: musicConstants.GET_PLAYLIST_FAILURE, error } }
}

//this function is to get all the releases that belong to a playlist
function getplaylistreleases(urlname){
    return dispatch => {
        musicService.getplaylistreleases(urlname)
            .then(
                response => { 
                   dispatch(success(response.allreleases, response.listInfo));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(playlistsreleases, listInfo) { return { type: musicConstants.GET_PLAYLISTRELEASES_SUCCESS, playlistsreleases, listInfo}}
    function failure(error) { return { type: musicConstants.GET_PLAYLIST_FAILURE, error } }
}

function addsubscriber(urlname){
     return dispatch => {
        musicService.addsubscriber(urlname)
            .then(
                subscriber => {
                    dispatch(messageup(subscriber.message)); 
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function messageup (releasemessage) { return { type: musicConstants.MESSAGE_ONLY, releasemessage} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
}

function getmusicgenres(){
    return dispatch => {
       musicService.getmusicgenres()
           .then(
                musicgenres => {
                   dispatch(success(musicgenres)); 
               },
               error => {
                   dispatch(failure(error.toString()));
                   dispatch(alertActions.error(error.toString()));
               }
           );
   }
   function success (musicgenres) { return { type: musicConstants.GET_GENRES_SUCCESS, musicgenres} }
   function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
}

//this function is to handle all request coming from top
function topnav(item){
    return dispatch => {
        musicService.topnav(item)
            .then(
                music => { 
                   dispatch(success(music.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(music) { return { type: musicConstants.GET_SUCCESS, music } }
    function failure(error) { return { type: musicConstants.GET_FAILURE, error } }
}

//this function is to get the joined musics for the home music page
function joinedmusics(username){
    
    return dispatch => {
        musicService.joinedmusics(username)
            .then(
                musics => { 
                   dispatch(success(musics));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(musics) { return { type: musicConstants.GET_JOINED_SUCCESS, musics } }
    function failure(error) { return { type: musicConstants.GET_JOINED_FAILURE, error } }
}

//this function is to get the details of a single music  item
function getmusicItem(urlname){
    return dispatch => {
        musicService.getmusicItem(urlname)
            .then(
                musicdetails => {
                    if(musicdetails.isCode === 100){
                        dispatch(messageup(musicdetails.message)); 
                    }
                    else{
                         dispatch(success(musicdetails.releaseinfo)); 
                    }
                  
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function messageup (releasemessage) { return { type: musicConstants.MESSAGE_ONLY, releasemessage} }
    function success(musicdetails) { return { type: musicConstants.GET_ITEM_SUCCESS, musicdetails } }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
}

//this function is to stream a song
 function streammusic(urlname){
    return dispatch => {
        musicService.streammusic(urlname)
            .then(
                stream => {
                   dispatch(success(stream.message, stream.releaseinfo));
                   if(stream.isCode === 201){
                        window.location.reload()
                   }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success( releasemessage, musicdetails) { return { type: musicConstants.GET_ITEM_SUCCESS, releasemessage, musicdetails } }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
 }

//this function is to stream a song
 function streammusicPlaylist(urlname, index){
    return dispatch => {
        musicService.streammusic(urlname)
            .then(
                stream => {
                   dispatch(success(stream.message, stream.releaseinfo, index));
                  
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success( releasemessage, musicdetails, index) { return { type: musicConstants.GET_ITEM_SUCCESS, releasemessage, musicdetails, index } }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
 }

//this function is to to add song pto playlist
 function addtoplaylist(info){
    return dispatch => {
        musicService.addtoplaylist(info)
            .then(
                playlist => {
                   dispatch(success(playlist.message));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success( releasemessage) { return { type: musicConstants.MESSAGE_ONLY, releasemessage} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
 }


//this function is to to remove song from playlist
 function removefromlist(data){
    return dispatch => {
        musicService.removefromlist(data)
            .then(
                playlist => {
                   dispatch(success(playlist.message));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success( releasemessage) { return { type: musicConstants.MESSAGE_ONLY, releasemessage} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
 }

 //this function is to stream a song
 function getspackages(){
    return dispatch => {
        musicService.spackages()
            .then(
                packages => {
                   dispatch(success(packages));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success( packages) { return { type: musicConstants.GET_ITEM_SUCCESS, packages} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
 }


 //this function is to stream a song
 function footerplayer(){
    return dispatch => {
        musicService.footerplayer()
            .then(
                footermusics => {
                   dispatch(success(footermusics));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success( footermusics) { return { type: musicConstants.GET_FOOTER_ITEM_SUCCESS, footermusics} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
 }


//this function is to activate a streaming package
 function activatep(idPackage){
    return dispatch => {
        musicService.activatep(idPackage)
            .then(
                activepackage => {
                   dispatch(success(activepackage.message));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success( financialmessage) { return { type: financialConstants.GET_SUCCESS, financialmessage} }
    function failure(error) { return { type: financialConstants.GET_ITEM_FAILURE, error } }
 }


//this function is to stream a song
 function buymusic(urlname, rtype){
    return dispatch => {

        musicService.buymusic(urlname, rtype)
            .then(
                buy => {
                   dispatch(success(buy.message, buy.releaseinfo));
                   if(buy.isCode === 201){
                        window.location.reload()
                   }

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success( releasemessage, musicdetails) { return { type: musicConstants.GET_ITEM_SUCCESS, releasemessage, musicdetails } }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
 }

//this function is to stream a song
 function buymusicPlaylist(urlname, rtype, index){
    return dispatch => {

        musicService.buymusic(urlname, rtype)
            .then(
                buy => {
                   dispatch(success(buy.message, buy.releaseinfo, index));
                
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success( releasemessage, musicdetails, index) { return { type: musicConstants.GET_ITEM_SUCCESS, releasemessage, musicdetails , index } }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
 }

//this function is to stream a song
 function purchaseunauth(urlname, amount, name, phoneNum, emailAddress, method){
    return dispatch => {

        musicService.purchaseunauth(urlname, amount, name, phoneNum, emailAddress,method)
            .then(
                buy => {
                    /*if(method === 'Orange Money' && buy.isCode === 201){
                        //get datas.results for orange money and redirect the webpage to the url found inside
                         //history.push(datas.results.payment_url);
                          window.location.replace(buy.results.payment_url)
                    }*/
                    if(buy && buy.user && buy.newuser){
                        //login the user
                        let userType = 'Lover';
                        localStorage.setItem('lover','Lover');
                        localStorage.setItem('user', JSON.stringify(buy.user));
                        dispatch(loginsuccess(buy.user, userType));
                        window.location.reload()
                    }
                   dispatch(success(buy.message));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success( releasemessage) { return { type: musicConstants.GET_ITEM_SUCCESS, releasemessage} }
    function loginsuccess(user, userType) { return { type: userConstants.LOGIN_SUCCESS, user, userType } }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
}

//this function is to delete a release
 function deletemusic(releaseId){
    return dispatch => {
        musicService.deletemusic(releaseId)
            .then(
                release => {
                   dispatch(success(release.message, release.deleted.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(releasemessage, music) { return { type: musicConstants.GET_SUCCESS, releasemessage, music} }
    function failure(error) { return { type: musicConstants.GET_FAILURE, error } }
 }

 //this function is to update release data
 function updatemusic(info){
    return dispatch => {
        musicService.updatemusic(info)
            .then(
                info => {
                   dispatch(success(info));
                   history.push('/musics');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(info) { return { type: musicConstants.GET_ITEM_SUCCESS, info} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
 }

//this function is to update release data
 function createmusic(info){
    return dispatch => {
        musicService.createmusic(info)
            .then(
                datas => {
                   dispatch(success(datas.message, datas));
                   history.push('/musics');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));

                }
            );
    }
    function success(releasemessage, info) { return { type: musicConstants.GET_ITEM_SUCCESS, releasemessage, info} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
 }


//this function is to record number of downloads
function downloadSong(info){
    return dispatch => {
        musicService.downloadSong(info)
            .then(
                res => { 
                    dispatch(success(res.message));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(releasemessage) { return { type: musicConstants.MESSAGE_ONLY, releasemessage} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
}

//count the stream of this song
function countstreams(info){
    return dispatch => {
        musicService.countstreams(info)
            .then(
                res => { 
                    dispatch(success(res.message));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(releasemessage) { return { type: musicConstants.MESSAGE_ONLY, releasemessage} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
}

/**
 * This is to set playlist for an entire page. starting with indvidual playlist
*/
function pageplaylist(username){
    return dispatch => {
        musicService.pageplaylist(username)
            .then(
                datas => {
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(playlistdata) { return { type: musicConstants.GET_PLAYLIST_SUCCESS, playlistdata} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
}
/**
 * This sis to get all playlist so as to select songs from
 */
function getallplaylists(){
    return dispatch => {
        musicService.getallplaylists()
            .then(
                datas => {
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(playlists) { return { type: musicConstants.GET_PLAYLIST_JOINED_SUCCESS, playlists} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
}

/**
 * This is to get the different people in the playlists type
*/
function getplaylistsusers(item){
    return dispatch => {
        musicService.getplaylistsusers(item)
            .then(
                datas => {
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(playlists) { return { type: musicConstants.GET_PLAYLIST_SUCCESS, playlists} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
}

function getallcharts(){
    return dispatch => {
        musicService.getallcharts()
            .then(
                charts => {
                   dispatch(success(charts));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(charts) { return { type: musicConstants.GET_CHARTS_SUCCESS, charts} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
}

function pagechart(chartUrl){
    return dispatch => {
        musicService.pagechart(chartUrl)
            .then(
                chartdatas => {
                   dispatch(success(chartdatas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(chartdatas) { return { type: musicConstants.GET_CHARTS_ITEM_SUCCESS, chartdatas} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
}

/**
 * This is to get all the releases of the user on the manage page
*/
function mainmanage(){
    return dispatch => {
        musicService.mainmanage()
            .then(
                myreleases => {
                   dispatch(success(myreleases.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(myreleases) { return { type: musicConstants.GET_MANAGE_SUCCESS, myreleases} }
    function failure(error) { return { type: musicConstants.GET_ITEM_FAILURE, error } }
}

function toggleshowreleasesales(params){
    return dispatch => {
        musicService.toggleshowreleasesales(params)
            .then(
                response => {
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(response) { return { type: musicConstants.TOGGLE_SHOW_RELEASE_SALES_SUCCESS, response} }
    function failure(error) { return { type: musicConstants.TOGGLE_SHOW_RELEASE_SALES_FAILURE, error } }
}
