import { musicConstants } from '../_constants';

export function musiction(state = {}, action) {
    switch (action.type) {
        case musicConstants.GET_SUCCESS:
            return {
                musicState: true,
                music: action.music,
                releasemessage:action.releasemessage
            };
        case musicConstants.GET_FAILURE:
            return {
                musicState: false,
                error:action.error 
            };
        default:
          return state
    }
}

export function musicdetion(state={}, action){
    switch (action.type) {
        case musicConstants.GET_ITEM_SUCCESS:
            return {
                musicState: true,
                musicitem: action.musicdetails
            };
        case musicConstants.GET_ITEM_FAILURE:
            return {
                musicState: false,
                error:action.error 
            };
        default:
          return state
    }
}

export function musicsmessage(state={}, action){
    switch (action.type) {
        case musicConstants.GET_ITEM_SUCCESS:
            return {
                rmessageState: true,
                releasemessage: action.releasemessage,
                index: action.index
            };
          case musicConstants.MESSAGE_ONLY:
            return {
                rmessageState: true,
                releasemessage: action.releasemessage
            };
        case musicConstants.GET_ITEM_FAILURE:
            return {
                rmessageState: false,
                error:action.error 
            };
        default:
          return state
    }
}

export function streamingpackages(state={}, action){
    switch (action.type) {
        case musicConstants.GET_ITEM_SUCCESS:
            return {
                rmessageState: true,
                packages: action.packages,
            };
        case musicConstants.GET_ITEM_FAILURE:
            return {
                rmessageState: false,
                error:action.error 
            };
        default:
          return state
    }
}


export function footermusics(state={}, action){
    switch (action.type) {
        case musicConstants.GET_FOOTER_ITEM_SUCCESS:
            return {
                rmessageState: true,
                footermusics: action.footermusics,
            };
        case musicConstants.GET_ITEM_FAILURE:
            return {
                rmessageState: false,
                error:action.error 
            };
        default:
          return state
    }
}

export function musicplaylists(state={}, action){
    switch (action.type) {
        case musicConstants.GET_PLAYLIST_SUCCESS:
            return {
                musicState: true,
                playlists: action.playlists

            };
        case musicConstants.GET_PLAYLIST_FAILURE:
            return {
                musicState: false,
                error:action.error 
            };
        default:
           return state
    }
    
}

export function playlistreleases(state={}, action){
    switch (action.type) {
        case musicConstants.GET_PLAYLISTRELEASES_SUCCESS:
            return {
                musicState: true,
                playlistsreleases: action.playlistsreleases,
                listInfo: action.listInfo
            };
        case musicConstants.GET_PLAYLIST_FAILURE:
            return {
                musicState: false,
                error:action.error 
            };
        default:
           return state
    }
}

export function joinedmusiction(state={}, action){
    switch (action.type) {
        case musicConstants.GET_JOINED_SUCCESS:
            return {
                musicState: true,
                musics:action.musics
            };
        case musicConstants.GET_JOINED_FAILURE:
            return {
                musicState: false,
                error:action.error 
            };
        default:
           return state
    }
}


export function musicgenretion(state={}, action){
    switch (action.type) {
        case musicConstants.GET_GENRES_SUCCESS:
            return {
                musicState: true,
                musicgenres:action.musicgenres
            };
        case musicConstants.GET_ITEM_FAILURE:
            return {
                musicState: false,
                error:action.error 
            };
        default:
           return state
    }
}

export function musicplaylistion(state={}, action){
    switch (action.type) {
        case musicConstants.GET_PLAYLIST_SUCCESS:
            return {
                musicState: true,
                playlistdata:action.playlistdata
            };
        case musicConstants.GET_ITEM_FAILURE:
            return {
                musicState: false,
                error:action.error 
            };
        default:
           return state
    }
}

export function joinedplaylistion(state={}, action){
    switch (action.type) {
        case musicConstants.GET_PLAYLIST_JOINED_SUCCESS:
            return {
                musicState: true,
                playlists:action.playlists
            };
        case musicConstants.GET_ITEM_FAILURE:
            return {
                musicState: false,
                error:action.error 
            };
        default:
           return state
    }
}

export function chartion(state={}, action){
    switch (action.type) {
        case musicConstants.GET_CHARTS_SUCCESS:
            return {
                musicState: true,
                charts:action.charts.allcharts
            };
        case musicConstants.GET_CHARTS_ITEM_SUCCESS:
                return {
                    musicState: true,
                    chartdatas:action.chartdatas
                };
        case musicConstants.GET_ITEM_FAILURE:
            return {
                musicState: false,
                error:action.error 
            };
        default:
           return state
    }
}

export function toggleshowreleasesales(state={}, action){
    switch (action.type) {
        case musicConstants.TOGGLE_SHOW_RELEASE_SALES_SUCCESS:
            return {
                showSalesState: true,
                response: action.response
            };
        case musicConstants.TOGGLE_SHOW_RELEASE_SALES_FAILURE:
            return {
                showSalesState: false,
                error: action.error 
            };
        default:
           return state
    }
}

export function mainmanagion(state={}, action){
    switch (action.type) {
        case musicConstants.GET_MANAGE_SUCCESS:
            return {
                musicManageState: true,
                myreleases: action.myreleases
            };
        case musicConstants.GET_MANAGE_FAILURE:
            return {
                musicManageState: false,
                error: action.error 
            };
        default:
           return state
    }
}