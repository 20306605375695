export const financialConstants = {
    GET_SUCCESS: 'GET_SUCCESS',
    GET_FAILURE: 'GET_FAILURE',
    GET_ALL_GIFT_REQUEST_SUCCESS: 'GIFT_REQUEST_GET_SUCCESS',
    GET_ALL_GIFT_REQUEST_FAILURE: 'GIFT_REQUEST_GET_FAILURE',
    ACCEPT_GIFT_SUCCESS: 'GIFT_ACCEPT_SUCCESS',
    ACCEPT_GIFT_FAILURE: 'GIFT_ACCEPT_FAILURE',
    DECLINE_GIFT_SUCCESS: 'GIFT_DECLINE_SUCCESS',
    DECLINE_GIFT_FAILURE: 'GIFT_DECLINE_FAILURE',
};
