
import { apiConstants } from '../_constants';
//import { authHeader } from '../_helpers';

export const relationsService = {
    friendrequest,
    follow,
    block,
    unblock,
    getfollowers,
    getfollowings,
    getfriends,
    getFriendRequest,
    acceptFriend,
    declineFriend,
    unfollow
};

//this function is to get
function friendrequest(username) {

    let user = JSON.parse(localStorage.getItem('user'));
    if(!user || user === null ){
        user = {};
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
        username: username
    })
    };
    return fetch(apiConstants.API_RELATIONS_URL+'friendrequestsent?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//follow an artist
function follow(username) {
    let user = JSON.parse(localStorage.getItem('user'));
    if(!user || user === null ){
         user = {};
    }
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
        username: username
    })
    };
    return fetch(apiConstants.API_RELATIONS_URL+'follow?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//follow an artist
function unfollow(username) {
    let user = JSON.parse(localStorage.getItem('user'));
    if(!user || user === null ){
         user = {};
    }
   
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
        username: username
    })
    };
    return fetch(apiConstants.API_RELATIONS_URL+'unfollow?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//follow an artist
function block(username) {
let user = JSON.parse(localStorage.getItem('user'));
 const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
        username: username
    })
    };
    return fetch(apiConstants.API_RELATIONS_URL+'block?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//follow an artist
function unblock(username) {
let user = JSON.parse(localStorage.getItem('user'));
 const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
        username: username
    })
    };
    return fetch(apiConstants.API_RELATIONS_URL+'unblock?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//get all followersof an artist
function getfollowers(username) {
let user = JSON.parse(localStorage.getItem('user'));
 const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
        username: username
    })
    };
    return fetch(apiConstants.API_RELATIONS_URL+'allfollowers?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//get all of the users, followings
function getfollowings(username) {
let user = JSON.parse(localStorage.getItem('user'));
 const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
        username: username
    })
    };
    return fetch(apiConstants.API_RELATIONS_URL+'allfollowings?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}


//get all user's friends
function getfriends(username) {
let user = JSON.parse(localStorage.getItem('user'));
 const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
        username: username
    })
    };
    return fetch(apiConstants.API_RELATIONS_URL+'allfriends?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//get all friend requests
function getFriendRequest(username) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            username: username
        })
    };
    return fetch(apiConstants.API_RELATIONS_URL+'allfriendrequest?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//accept friend requests
function acceptFriend(username) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            username: username
        })
    };
    return fetch(apiConstants.API_RELATIONS_URL+'friendaccept?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//decline friend requests
function declineFriend(username) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            username: username
        })
    };
    return fetch(apiConstants.API_RELATIONS_URL+'frienddecline?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
