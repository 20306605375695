import { messageConstants } from '../_constants';

export function inboxtion(state = {}, action) {
    switch (action.type) {
        case messageConstants.GET_MESSAGE_SUCCESS:
            return {
                messageInboxState: true,
                messages: action.messages
            };
        case messageConstants.GET_MESSAGE_FAILURE:
            return {
                messageInboxState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function messagedetailtion(state = {}, action) {
    switch (action.type) {
        case messageConstants.GET_MESSAGE_DETAILS_SUCCESS:
            return {
                messageDetailsState: true,
                messages: action.messages
            };
        case messageConstants.GET_MESSAGE_DETAILS_FAILURE:
            return {
                messageDetailsState: false,
                error:action.error 
            };
        default:
        return state
    }
}

export function messagereplytion(state = {}, action) {
    switch (action.type) {
        case messageConstants.REPLY_MESSAGE_SUCCESS:
            return {
                messageReplyState: true,
                response: action.response
            };
        case messageConstants.REPLY_MESSAGE_FAILURE:
            return {
                messageReplyState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// messages sent by a user
export function sentmessagetion(state = {}, action) {
    switch (action.type) {
        case messageConstants.SENT_MESSAGE_SUCCESS:
            return {
                messageSentState: true,
                messages: action.messages
            };
        case messageConstants.SENT_MESSAGE_FAILURE:
            return {
                messageSentState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// unread messages
export function unreadmessagetion(state = {}, action) {
    switch (action.type) {
        case messageConstants.UNREAD_MESSAGE_SUCCESS:
            return {
                messageUnreadState: true,
                messages: action.messages
            };
        case messageConstants.UNREAD_MESSAGE_FAILURE:
            return {
                messageUnreadState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// thread messages
export function threadmessagetion(state = {}, action) {
    switch (action.type) {
        case messageConstants.THREAD_MESSAGE_SUCCESS:
            return {
                messageThreadState: true,
                messages: action.messages
            };
        case messageConstants.THREAD_MESSAGE_FAILURE:
            return {
                messageThreadState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// compose a mail
export function composemailtion(state = {}, action) {
    switch (action.type) {
        case messageConstants.COMPOSE_MAIL_SUCCESS:
            return {
                composeMailState: true,
                response: action.response
            };
        case messageConstants.COMPOSE_MAIL_FAILURE:
            return {
                composeMailState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// get all users
export function getallusertion(state = {}, action) {
    switch (action.type) {
        case messageConstants.GET_USERS_SUCCESS:
            return {
                usersState: true,
                users: action.users
            };
        case messageConstants.GET_USERS_FAILURE:
            return {
                usersState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// create a chat
export function createchation(state = {}, action) {
    switch (action.type) {
        case messageConstants.CREATE_CHAT_SUCCESS:
            return {
                chatState: true,
                response: action.response
            };
        case messageConstants.CREATE_CHAT_FAILURE:
            return {
                chatState: false,
                error:action.error
            };
        default:
        return state
    }
}

// send a chat message
export function sendchation(state = {}, action) {
    switch (action.type) {
        case messageConstants.SEND_CHAT_SUCCESS:
            return {
                chatState: true,
                messages: action.messages
            };
        case messageConstants.SEND_CHAT_FAILURE:
            return {
                chatState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// get user of a chat window
const initState = {
    allChatMessages: [], // array of the messages of opened chat windows
    allChatMessagesUsers: [] // array of users of chat windows
}
export function getChatWindowUser(state = initState, action) {
    switch (action.type) {
        case messageConstants.GET_CHAT_USER_SUCCESS:  
            if(state.allChatMessagesUsers){
                if(!state.allChatMessagesUsers.find(user => user.username === action.user.username)){
                    state.allChatMessagesUsers.push(action.user);
                }
            }
            console.log(state.allChatMessagesUsers);
            return {
                ...state,
                userState: true,
                counterUser: state.allChatMessagesUsers
            };
        case messageConstants.GET_CHAT_USER_FAILURE:
            return {
            userState: false,
            error:action.error 
        };
        default:
        return state
    }
}

// get messages of a chat
export function chatdetailtion(state = initState, action) {
    switch (action.type) {
        case messageConstants.GET_CHAT_DETAILS_SUCCESS:
            if(state.allChatMessages){
                let chatMessage = {
                    key: action.username,
                    message: action.messages.message,
                    isSuccess: action.messages.isSuccess,
                    chat: action.messages.chat,
                    chatmessages: action.messages.chatmessages,
                };
                state.allChatMessages.push(chatMessage);
            }
            return {
                ...state,
                chatDetailsState: true,
                messages: state.allChatMessages
            };
        case messageConstants.GET_CHAT_DETAILS_FAILURE:
            return {
                chatDetailsState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// get latest chats and friends
export function chatsalltion(state = {}, action) {
    switch (action.type) {
        case messageConstants.GET_CHATS_ALL_SUCCESS:
            return {
                chatsAllState: true,
                chatsall: action.chatsall
            };
        case messageConstants.GET_CHATS_ALL_FAILURE:
            return {
                chatsAllState: false,
                error:action.error 
            };
        default:
        return state
    }
}