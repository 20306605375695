import { apiConstants } from '../_constants';

export const userService = {
    login,
    logout,
    testers,
    profileInfo,
    getUser,
    searchmusics,
    searchusers,
    searchevents,
    updateInfo,
    changepassword,
    changeusername,
    changeartist,
    changeprofilepic,
    getNotifications,
    navbadges,
};


function testers(code) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code})
    };
    return fetch(apiConstants.API_BEFORE_URL+'validatetest', requestOptions)
         .then(handleResponse)
        .then(json => {
            // login successful if there's a jwt token in the response
            if (json.isSuccess === 201 && json.user) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('tester', JSON.stringify(json.tester));
            }
            return json;
        });
}

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    return fetch(apiConstants.API_BEFORE_URL+'login', requestOptions)
         .then(handleResponse)
        .then(json => {
            // login successful if there's a jwt token in the response
            if (json.isSuccess === 201 && json.user) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(json.user));
            }
            return json;
        });
}

function logout() {
    // remove user from local storage to log user out
    //localStorage.removeItem('tester');
    localStorage.removeItem('user');
    localStorage.removeItem('lover');
    localStorage.removeItem('artist');
    //localStorage.removeItem('tester');
    localStorage.removeItem('admin');
    
}

//this is to get the user profile data
function profileInfo(username){
     let user = JSON.parse(localStorage.getItem('user'));
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username})
    };
    return fetch(apiConstants.API_GENERAL_URL+'profileinfo?access-token='+user.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this is to get a specific user
function getUser(username){
     let user = JSON.parse(localStorage.getItem('user'));
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username})
    };
    return fetch(apiConstants.API_GENERAL_URL+'getuser?access-token='+user.auth_key, requestOptions)
   .then(handleResponse)
        .then(json => {
            return json;
        });
}
  
//this is to get the user profile data
function searchmusics(svalue){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            svalue,   
        })
    };
    return fetch(apiConstants.API_SEARCH_URL+'searchmusics', requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this is to get the user profile data
function searchusers(svalue){
     let user = JSON.parse(localStorage.getItem('user'));
    let userId = 0;
    if(user){
         userId = user.pid;
    }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ svalue, userId})
    };
    return fetch(apiConstants.API_SEARCH_URL+'searchusers', requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this is to get the user profile data
function searchevents(svalue){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ svalue})
    };
    return fetch(apiConstants.API_SEARCH_URL+'searchevents',requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this is to get the user profile data
function updateInfo(info){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info)
    };
    return fetch(apiConstants.API_GENERAL_URL+'updateinfo?access-token='+user.auth_key,requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this is to get the user profile data
function changepassword(password, newpassword){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            password,
            newpassword
        })
    };
    return fetch(apiConstants.API_GENERAL_URL+'changepassword?access-token='+user.auth_key,requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this is to get the user profile data
function changeprofilepic(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(apiConstants.API_GENERAL_URL+'getuserinfo?access-token='+user.auth_key,requestOptions)
         .then(handleResponse)
        .then(json => {
             localStorage.setItem('user', JSON.stringify(json.user));
            return json;
        });
}

//this is to get the user profile data
function changeusername(info){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info)
    };
    return fetch(apiConstants.API_GENERAL_URL+'updateusername?access-token='+user.auth_key,requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function changeartist(info){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info)
    };
    return fetch(apiConstants.API_GENERAL_URL+'updateartistinfo?access-token='+user.auth_key,requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// get the notifications of a user
function getNotifications(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(apiConstants.API_GENERAL_URL+'getnotifications?access-token='+user.auth_key,requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}


// get the notifications of a user
function navbadges(){
    let user = JSON.parse(localStorage.getItem('user'));
    if(!user){
        user = {};
    }
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(apiConstants.API_GENERAL_URL+'navbadges?access-token='+user.auth_key,requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}