import React, { Component, Suspense } from 'react';
import { Container, Modal, Button} from 'mdbreact';
import './App.css';
import { connect } from 'react-redux';
import UserDashboardNavbar from './Navbar/UserDashboardNavbar.js';
import HomeNavbar from './Navbar/HomeNavbar.js';
import FooterPage from './Footer/Footer.js';
import { I18nProvider } from '@lingui/react';
import FooterPlayer from './Music/FooterPlayer';
//import Entry from './Home/Entry';
import {withRouter} from 'react-router-dom';
import { history } from './_helpers';

const Main = React.lazy(() => import("././Main/Main.js"));


class App extends Component {
    
    state = {
        catalogs: {},
        modal1:true,
         loading: true // appears after page has loaded
    }
    loadCatalog = async (language) => {
      const catalog = await import(
        '@lingui/loader!./locales/'+language+'/messages.po').then(mod => mod.default)
      this.setState(state => ({
        catalogs: {
          ...state.catalogs,
          [language]: catalog
        }
      }))
    }

    componentWillMount(){
      //ad wa
    }
    componentDidMount() {
        //verifying the testers information
        //localStorage.removeItem('tester');
        //window.__OG_TITLE__ = "testing new page";
        let tester = localStorage.getItem('tester');
        if(tester){
            this.setState({
              tester:tester
            })
        }
        setTimeout(() => this.setState({ loading: false }), 1500); // simulates an async action, and hides the spinner
        this.loadCatalog(this.props.language);
        
        //checking wether a songtitle is set so that we can redirect to song page.
        const songtitle = localStorage.getItem('songtitle');
        //unset the localItem
        localStorage.removeItem('songtitle');
        if(songtitle && this.props.user){
          history.push('/musics/'+songtitle);
        }

        const eventtitle = localStorage.getItem('eventtitle');
        //unset the localItem
        localStorage.removeItem('eventtitle');
        if(eventtitle && this.props.user){
          history.push('/events/'+eventtitle);
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
      const { language } = nextProps
      const { catalogs } = nextState
      if (language !== this.props.language && !catalogs[language]) {
        this.loadCatalog(language)
        return false
      }
      return true
    }

    //cancel the modals
    cancel(nr){
        let modalNumber = 'modal' + nr
        this.setState({
          [modalNumber]: false,
        });
    }
     //to toogle the mogals
     toggle(nr) {
      let modalNumber = 'modal' + nr
      this.setState({
        [modalNumber]: !this.state[modalNumber],
      });
  }


    render() {
        const { loading } = this.state;
        const {user, language} = this.props;
        const { catalogs} = this.state;
       
        if (!catalogs[language]) return null;
        if(loading) {
            return (
                <div className="text-center">
		
                	<div  className="space-above container-fluid">
                    <div className="spinner-border text-primary  spinner-grow-lg" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                	</div>
                </div>

            )
        }
        return (
            <I18nProvider language={language} catalogs={catalogs} >
                    <div className="App">
                        {user ?
                          <div className="space-nav">
                            {/**
                             * Show a modal that says activiate your account
                            */}
                            {user.activation_status ===0 &&
                              <div>
                                 <Modal isOpen={this.state.modal1} toggle={() => this.toggle(1)} fullHeight position="left">
                                    <Container className="space-above container-fluid text-center">
                                      <div>
                                            <div>  
                                              <div className="pull-right">
                                                  <Button className="close" data-dismiss="modal" aria-label="Close" onClick={()=> this.toggle(1)} size="sm">
                                                      <span aria-hidden="true">&times;</span>
                                                  </Button>
                                              </div>
                                            </div>
                                            
                                            <div>
                                              
                                              <div className="space-above"><h2><b>Welcome to Muzikol</b></h2></div>
                                              <br/>
                                              <div className="space text-black">
                                                Access your email <b>{user.email}</b> to Activate your Account by clicking on the Link provided. 
                                              </div>
                                            </div>
                                           
                                        </div>                        
                                    </Container>
                                </Modal>
                              </div>
                            }

                              <UserDashboardNavbar /> 
                              <div className="fixed-bottom">
                                <FooterPlayer />
                              </div>
                          </div>
                        :
                          <div>
                              <HomeNavbar />
                          </div>
                        }
                        {/* don't apply side paddings to the home page */}
                        <div className="main">
                           <Suspense fallback={
                                <div className="text-center">
                                    <div className="text-center">
                                      <div  className="space-above container-fluid">
                                        <div className="spinner-border text-primary  spinner-grow-lg" role="status">
                                          <span className="sr-only">Loading...</span>
                                        </div>
                                      </div>
                                    </div>
                                </div>}>
                                <Main  />
                            </Suspense>
                        </div>
                        <div className="space-above">
                            <FooterPage />
                        </div>
                    </div>
            </I18nProvider>
            
        );
    }
}
function mapStateToProps(state) {
  const { loggingIn, user, userType} = state.authentication;
  const { language } = state.languagetion;
    return {
        loggingIn,
        user,
        userType,
        language,
    };
}

export default connect(
  mapStateToProps,
)(withRouter(App))