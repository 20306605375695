import { adminConstants } from '../_constants';
import { adminService } from '../_services';
import { alertActions } from './';

export const adminActions = {
    getPendingAccounts,
    activateAccount,
    getPendingWithdrawals,
    acceptWithdrawal,
    rejectWithdrawal,
    getCompanyStaff,
    getTrendings,
    getBlockedUsers,
    unBlockUser,
    getBooks,
    saveBook,
    deleteBook,
    getReleases,
    deleteRelease,
    getAllUsers,
    deleteUser,
    createTester,
    deleteTester,
    resetpass,
    deactivateAccount,
    validateAcount,
    createUser,
    createArtist,
    editUser,
    editArtist,
    deleteTrend,
    changeTrendStatus,
    changeActivationStatus,
    getContactUs,
    deleteContactUs,
    replyContactUs,
};


function resetpass(info){
    return dispatch => {
        adminService.resetpass(info)
            .then(
                datas => { 
                   dispatch(success(datas.message));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(message) { return { type: adminConstants.GET_MESSAGE_SUCCESS, message } }
    function failure(error) { return { type: adminConstants.GET_MESSAGE_FAILURE, error } }
}


function validateAcount(user,artist){
    return dispatch => {
        adminService.validateAcount(user,artist)
            .then(
                datas => { 
                   dispatch(success(datas.message));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(message) { return { type: adminConstants.GET_MESSAGE_SUCCESS, message } }
    function failure(error) { return { type: adminConstants.GET_MESSAGE_FAILURE, error } }
}

function createTester(info){
    return dispatch => {
        adminService.createTester(info)
            .then(
                datas => { 
                   dispatch(success(datas.message));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(message) { return { type: adminConstants.GET_MESSAGE_SUCCESS, message } }
    function failure(error) { return { type: adminConstants.GET_MESSAGE_FAILURE, error } }
}

function deleteTester(idTestcodes){
    return dispatch => {
        adminService.deleteTester(idTestcodes)
            .then(
                datas => { 
                   dispatch(success(datas.message));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(message) { return { type: adminConstants.GET_MESSAGE_SUCCESS, message } }
    function failure(error) { return { type: adminConstants.GET_MESSAGE_FAILURE, error } }
}


function getPendingAccounts(){
    return dispatch => {
        adminService.getPendingAccounts()
            .then(
                datas => { 
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(accounts) { return { type: adminConstants.GET_PENDING_ACCOUNTS_SUCCESS, accounts } }
    function failure(error) { return { type: adminConstants.GET_PENDING_ACCOUNTS_FAILURE, error } }
}

function deactivateAccount(username){
    return dispatch => {
        adminService.deactivateAccount(username)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.ACTIVATE_ACCOUNTS_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.ACTIVATE_ACCOUNTS_FAILURE, error } }
}


function activateAccount(username){
    return dispatch => {
        adminService.activateAccount(username)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.ACTIVATE_ACCOUNTS_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.ACTIVATE_ACCOUNTS_FAILURE, error } }
}

function getPendingWithdrawals(){
    return dispatch => {
        adminService.getPendingWithdrawals()
            .then(
                datas => { 
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(accounts) { return { type: adminConstants.GET_PENDING_WITHDRAWALS_SUCCESS, accounts } }
    function failure(error) { return { type: adminConstants.GET_PENDING_WITHDRAWALS_FAILURE, error } }
}

function acceptWithdrawal(idWithdraw){
    return dispatch => {
        adminService.acceptWithdrawal(idWithdraw)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.ACCEPT_WITHDRAWAL_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.ACCEPT_WITHDRAWAL_FAILURE, error } }
}

function rejectWithdrawal(idWithdraw){
    return dispatch => {
        adminService.rejectWithdrawal(idWithdraw)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.REJECT_WITHDRAWAL_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.REJECT_WITHDRAWAL_FAILURE, error } }
}

function getCompanyStaff(){
    return dispatch => {
        adminService.getCompanyStaff()
            .then(
                members => { 
                   dispatch(success(members));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(members) { return { type: adminConstants.GET_COMPANY_STAFF_SUCCESS, members } }
    function failure(error) { return { type: adminConstants.GET_COMPANY_STAFF_FAILURE, error } }
}

function getTrendings(){
    return dispatch => {
        adminService.getTrendings()
            .then(
                trendings => { 
                   dispatch(success(trendings));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(trendings) { return { type: adminConstants.GET_TRENDING_SUCCESS, trendings } }
    function failure(error) { return { type: adminConstants.GET_TRENDING_FAILURE, error } }
}

function getBlockedUsers(){
    return dispatch => {
        adminService.getBlockedUsers()
            .then(
                busers => { 
                   dispatch(success(busers));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(busers) { return { type: adminConstants.GET_BLOCKED_USERS_SUCCESS, busers } }
    function failure(error) { return { type: adminConstants.GET_BLOCKED_USERS_FAILURE, error } }
}

function unBlockUser(idBlock){
    return dispatch => {
        adminService.unBlockUser(idBlock)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.UNBLOCK_USER_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.UNBLOCK_USER_FAILURE, error } }
}

function getBooks(){
    return dispatch => {
        adminService.getBooks()
            .then(
                books => { 
                   dispatch(success(books));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(books) { return { type: adminConstants.GET_BOOKS_SUCCESS, books } }
    function failure(error) { return { type: adminConstants.GET_BOOKS_FAILURE, error } }
}

function saveBook(bookdetails, idBook){
    return dispatch => {
        adminService.saveBook(bookdetails, idBook)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.SAVE_BOOK_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.SAVE_BOOK_FAILURE, error } }
}

function deleteBook(idBook){
    return dispatch => {
        adminService.deleteBook(idBook)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.DELETE_BOOK_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.DELETE_BOOK_FAILURE, error } }
}

function getReleases(){
    return dispatch => {
        adminService.getReleases()
            .then(
                releases => { 
                   dispatch(success(releases));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(releases) { return { type: adminConstants.GET_RELEASES_SUCCESS, releases } }
    function failure(error) { return { type: adminConstants.GET_RELEASES_FAILURE, error } }
}

function deleteRelease(idRelease){
    return dispatch => {
        adminService.deleteRelease(idRelease)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.DELETE_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.DELETE_FAILURE, error } }
}

function getAllUsers(){
    return dispatch => {
        adminService.getAllUsers()
            .then(
                users => { 
                   dispatch(success(users));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(users) { return { type: adminConstants.GET_ALL_USERS_SUCCESS, users } }
    function failure(error) { return { type: adminConstants.GET_ALL_USERS_FAILURE, error } }
}

function deleteUser(idUser){
    return dispatch => {
        adminService.deleteUser(idUser)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.DELETE_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.DELETE_FAILURE, error } }
}

function createUser(userdata){
    return dispatch => {
        adminService.createUser(userdata)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.CREATE_USER_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.CREATE_USER_FAILURE, error } }
}

function createArtist(artistdata){
    return dispatch => {
        adminService.createArtist(artistdata)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(artistresponse) { return { type: adminConstants.CREATE_USER_SUCCESS, artistresponse } }
    function failure(error) { return { type: adminConstants.CREATE_USER_FAILURE, error } }
}

function editUser(userdata){
    return dispatch => {
        adminService.editUser(userdata)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.EDIT_USER_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.EDIT_USER_FAILURE, error } }
}

function editArtist(artistdata){
    return dispatch => {
        adminService.editArtist(artistdata)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.EDIT_USER_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.EDIT_USER_FAILURE, error } }
}

function deleteTrend(idTrend){
    return dispatch => {
        adminService.deleteTrend(idTrend)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.DELETE_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.DELETE_FAILURE, error } }
}

function changeTrendStatus(idTrend, status){
    return dispatch => {
        adminService.changeTrendStatus(idTrend, status)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.CHANGE_STATUS_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.CHANGE_STATUS_FAILURE, error } }
}

function changeActivationStatus(username, state){
    return dispatch => {
        adminService.changeActivationStatus(username, state)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.CHANGE_STATUS_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.CHANGE_STATUS_FAILURE, error } }
}

function getContactUs(){
    return dispatch => {
        adminService.getContactUs()
            .then(
                data => { 
                   dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(contacts) { return { type: adminConstants.GET_CONTACT_US_SUCCESS, contacts } }
    function failure(error) { return { type: adminConstants.GET_CONTACT_US_FAILURE, error } }
}

function deleteContactUs(idContactUs){
    return dispatch => {
        adminService.deleteContactUs(idContactUs)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.DELETE_CONTACT_US_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.DELETE_CONTACT_US_FAILURE, error } }
}

function replyContactUs(idContactUs, message){
    return dispatch => {
        adminService.replyContactUs(idContactUs, message)
            .then(
                response => { 
                   dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function success(response) { return { type: adminConstants.REPLY_CONTACT_US_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.REPLY_CONTACT_US_FAILURE, error } }
}
