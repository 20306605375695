import { messageConstants } from '../_constants';
import { messageService } from '../_services';
import { userService } from '../_services';
import { alertActions } from './';

export const messageActions = {
    inbox,
    messageDetails,
    replyMessage,
    sentMessages,
    unreadMessages,
    threadMessages,
    composeMail,
    getAllUsers,
    replyThread,
    createChat,
    sendChat,
    getChatWindowUser,
    chatDetails,
    getChatsAll,
};

function inbox() {
    return dispatch => {
        messageService.inbox()
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(messages) { return { type: messageConstants.GET_MESSAGE_SUCCESS, messages } }
    function failure(error) { return { type: messageConstants.GET_MESSAGE_FAILURE, error } }
}

function messageDetails(parentId) {
    return dispatch => {
        messageService.messageDetails(parentId)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(messages) { return { type: messageConstants.GET_MESSAGE_DETAILS_SUCCESS, messages } }
    function failure(error) { return { type: messageConstants.GET_MESSAGE_DETAILS_FAILURE, error } }
}

// reply to a message
function replyMessage(reply, parentId) {
    return dispatch => {
        messageService.replyMessage(reply, parentId)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(response) { return { type: messageConstants.REPLY_MESSAGE_SUCCESS, response } }
    function failure(error) { return { type: messageConstants.REPLY_MESSAGE_FAILURE, error } }
}

// messages sent by the user
function sentMessages() {
    return dispatch => {
        messageService.sentMessages()
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(messages) { return { type: messageConstants.SENT_MESSAGE_SUCCESS, messages } }
    function failure(error) { return { type: messageConstants.SENT_MESSAGE_FAILURE, error } }
}

// unread messages
function unreadMessages() {
    return dispatch => {
        messageService.unreadMessages()
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(messages) { return { type: messageConstants.UNREAD_MESSAGE_SUCCESS, messages } }
    function failure(error) { return { type: messageConstants.UNREAD_MESSAGE_FAILURE, error } }
}

// thread messages of a replied message
function threadMessages(parentId) {
    return dispatch => {
        messageService.threadMessages(parentId)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(messages) { return { type: messageConstants.THREAD_MESSAGE_SUCCESS, messages } }
    function failure(error) { return { type: messageConstants.THREAD_MESSAGE_FAILURE, error } }
}

// compose a mail
function composeMail(email, receiver) {
    return dispatch => {
        messageService.composeMail(email, receiver)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(response) { return { type: messageConstants.COMPOSE_MAIL_SUCCESS, response } }
    function failure(error) { return { type: messageConstants.COMPOSE_MAIL_FAILURE, error } }
}

// get all users
function getAllUsers() {
    return dispatch => {
        messageService.getAllUsers()
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(users) { return { type: messageConstants.GET_USERS_SUCCESS, users } }
    function failure(error) { return { type: messageConstants.GET_USERS_FAILURE, error } }
}

// reply to a thread
function replyThread(reply, idThread) {
    return dispatch => {
        messageService.replyThread(reply, idThread)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(response) { return { type: messageConstants.REPLY_THREAD_SUCCESS, response } }
    function failure(error) { return { type: messageConstants.REPLY_THREAD_FAILURE, error } }
}

// create a chat
function createChat(username, message) {
    return dispatch => {
        messageService.createChat(username, message)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(response) { return { type: messageConstants.CREATE_CHAT_SUCCESS, response} }
    function failure(error) { return { type: messageConstants.CREATE_CHAT_FAILURE, error } }
}

// send a chat message
function sendChat(chatId, message) {
    return dispatch => {
        messageService.sendChat(chatId, message)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(messages) { return { type: messageConstants.SEND_CHAT_SUCCESS, messages } }
    function failure(error) { return { type: messageConstants.SEND_CHAT_FAILURE, error } }
}

//this function is to get a specific user
function getChatWindowUser(username){
    return dispatch => {
        userService.getUser(username)
           .then(
                user => {
                    dispatch(success(user.user));
               },
               error => {
                   dispatch(failure(error.toString()));
                   dispatch(alertActions.error(error.toString()));
               }
        );
   }
    function success(user) { return { type: messageConstants.GET_CHAT_USER_SUCCESS, user} }
    function failure(error) { return { type: messageConstants.GET_CHAT_USER_FAILURE, error } }
}

// get messages of a chat
function chatDetails(username) {
    return dispatch => {
        messageService.chatDetails(username)
            .then(
                data => {
                    dispatch(success(data, username));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function success(messages, username) { return { type: messageConstants.GET_CHAT_DETAILS_SUCCESS, messages, username }}
    function failure(error) { return { type: messageConstants.GET_CHAT_DETAILS_FAILURE, error } }
}

// get latest chats and friends
function getChatsAll() {
    return dispatch => {
        messageService.getChatsAll()
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(chatsall) { return { type: messageConstants.GET_CHATS_ALL_SUCCESS, chatsall } }
    function failure(error) { return { type: messageConstants.GET_CHATS_ALL_FAILURE, error } }
}
