import React from 'react';
import { Col, Container, Row, Footer } from 'mdbreact';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { languageActions } from '../_actions';
import './Footer.css';


class FooterPage extends React.Component {

    constructor(props) {   
        super(props);
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    changeLanguage(event){
        event.preventDefault();
        const {value } = event.target;
        const { dispatch } = this.props;
        dispatch(languageActions.setLanguage(value));
    }

    render(){
        return(
            <Footer color="grey lighten-3" className="font-small space-above pt-3 site-second-color">
                <Container className="site-second-color" fluid>
                     <Row>
                        <Col sm="3">
                            <button value='en' className="btn btn-link footer-lang" onClick={this.changeLanguage} >English</button>
                        </Col>
                        <Col sm="3">
                            <button value='fr' className="btn btn-link footer-lang" onClick={this.changeLanguage} >French</button>
                        </Col>
                        <Col sm="3">
                            <button value='ar' className="btn btn-link footer-lang" onClick={this.changeLanguage} >Arabic</button>
                        </Col>
                        <Col sm="3">
                            <button value='ce' className="btn btn-link footer-lang" onClick={this.changeLanguage} >Chineese</button>
                        </Col>     
                    </Row>
                    <Row>
                        <Col sm="2">
                            <b className="text-dark">MUZIKOL</b>
                        </Col>
                        <Col sm="2">
                            <span className="text-dark footer-item-copywrite">&copy; Copyright {(new Date().getFullYear())} at ABEBOH</span>
                        </Col>
                        <Col sm="2">
                            <a href="/" className="text-dark footer-item-home">Home</a> 
                        </Col>
                        <Col sm="2">
                            <a href="/about" className="text-dark footer-item-slight-seperation">About Us</a>
                        </Col>
                        <Col sm="2">
                            <a href="/contact" className="text-dark footer-item-slight-seperation">Contact</a>
                        </Col>
                        <Col sm="2">
                            <a href="/terms" className="text-dark footer-item-terms">Terms | Policy</a>
                        </Col>
                    </Row>
                </Container>
            </Footer>
        );
    }
}

function mapStateToProps(state) {
   const { language} = state.languagetion;
    return {
        language
    };
}
function mapDispatchToProps(dispatch) {
   let actions = bindActionCreators({ ...languageActions });
  return { ...actions, dispatch };

}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterPage)
//export default FooterPage;