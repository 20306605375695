import { userConstants } from '../_constants';

let data = 'no data'
export function profiletion(state = {data}, action) {
  switch (action.type) {
    case userConstants.PROFILE_SUCCESS:  
      return {
        profile: true,
        data: action.info,
      };
    case userConstants.PROFILE_FAILURE:
      return {
          profile: false,
          error:action.error 
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}

//this function is to get the relationship users set
export function relationsusers(state={}, action){
    switch (action.type) {
        case userConstants.RELATIONS_SUCCESS:
            return {
                relationState: true,
                users: action.users
            };
        case userConstants.RELATIONS_FAILURE:
            return {
                relationsState: false,
                error:action.error 
            };
        default:
          return state
    }
}

//this function is to get the relationship users set
export function searchusers(state={}, action){
    switch (action.type) {
        case userConstants.RELATIONS_SUCCESS:
            return {
                searchState: true,
                searchusers: action.searchusers
            };
        case userConstants.RELATIONS_FAILURE:
            return {
                relationsState: false,
                error:action.error 
            };
        default:
          return state
    }
}

//this function is to get the relationship users set
export function searchmusics(state={}, action){
    switch (action.type) {
        case userConstants.RELATIONS_SUCCESS:
            return {
                searchState: true,
                searchmusics: action.searchmusics
            };
        case userConstants.RELATIONS_FAILURE:
            return {
                relationsState: false,
                error:action.error 
            };
        default:
          return state
    }
}


//this function is to get the relationship users set
export function searchvents(state={}, action){
    switch (action.type) {
        case userConstants.RELATIONS_SUCCESS:
            return {
                searchState: true,
                searchvents: action.searchvents
            };
        case userConstants.RELATIONS_FAILURE:
            return {
                relationsState: false,
                error:action.error 
            };
        default:
          return state
    }
}