import { combineReducers } from 'redux';
import { authentication, updatemessage, loadingReducer } from './authentication.reducer';
import { languagetion } from './language.reducer';
import { postion, postcreation, postcommention, postliketion, getdetailtion, replycommention, commentliketion,
	getreplycommention, getallartistion, postdeletion, postreportion, postupdation, getallreleasion, getallevention } from './post.reducer';
import { booktion, mybookstion, bookDetailstion, acceptbooktion, rejectbooktion } from './book.reducer';
import { profiletion,relationsusers, searchusers, searchmusics, searchvents } from './profile.reducer';
import { musiction, footermusics, musicdetion, musicsmessage,streamingpackages, musicplaylists, musicplaylistion,
		 playlistreleases, joinedmusiction ,musicgenretion, joinedplaylistion,chartion, toggleshowreleasesales,mainmanagion } from './music.reducer';
import { inboxtion, messagedetailtion, messagereplytion, sentmessagetion, unreadmessagetion, threadmessagetion,
	composemailtion, getallusertion, createchation, sendchation, getChatWindowUser, chatdetailtion, chatsalltion } from './message.reducer';
import {getusertion, getnotifications, navbadges} from './user.reducer';
import {allfriendtion, getallfriendrequestion, acceptfriendrequestion, declinefriendrequestion} from './relations.reducer';
import {evention, eventdetion, eventmessage, eventattending} from './event.reducer'
import {financialmessage,statistics, getpendings, acceptgift, declinegift } from './financial.reducer';
import {getpendingaccounts, adminmessages, activateAccount, getpendingwithdrawals, acceptwithdrawal, rejectwithdrawal, getcompanystaff, 
	gettrendings, getblockedusers, unblockuser, getbooks, savebook, getreleases, deleteresponse, getallusers, createuser, createartist, editua,
	deletetrend, changestatus, getcontactus, deletecontactus, replycontactus} from './admin.reducer';

const rootReducer = combineReducers({
	authentication,
	footermusics,
	languagetion,
	postion,
	postcreation,
	postliketion,
	commentliketion,
	postcommention,
	replycommention,
	getreplycommention,
	getdetailtion,
	booktion,
	mybookstion,
	bookDetailstion,
	acceptbooktion,
	rejectbooktion,
	profiletion,
	getallartistion,
	getallreleasion,
	getallevention,
	postdeletion,
	postreportion,
	postupdation,
	inboxtion,
	musiction,
	musicdetion,
	musicsmessage,
	mainmanagion,
	musicplaylists,
	playlistreleases,
	joinedmusiction,
	musicplaylistion,
	joinedplaylistion,
	musicgenretion,
	chartion,
	toggleshowreleasesales,
	messagedetailtion,
	messagereplytion,
	sentmessagetion,
	unreadmessagetion,
	threadmessagetion,
	composemailtion,
	getallusertion,
	createchation,
	getChatWindowUser,
	sendchation,
	chatdetailtion,
	chatsalltion,
	getusertion,
	getnotifications,
	allfriendtion,
	getallfriendrequestion,
	acceptfriendrequestion,
	declinefriendrequestion,
	evention,
	eventdetion,
	eventmessage,
	eventattending,
	financialmessage,
	statistics,
	getpendings,
	acceptgift,
	declinegift,
	streamingpackages,
	relationsusers,
	searchusers,
	searchmusics,
	searchvents,
	updatemessage,
	loadingReducer,
	navbadges,
	getpendingaccounts,
	adminmessages,
	activateAccount,
	getpendingwithdrawals,
	acceptwithdrawal,
	rejectwithdrawal,
	getcompanystaff,
	gettrendings,
	getblockedusers,
	unblockuser,
	getbooks,
	savebook,
	getreleases,
	deleteresponse,
	getallusers,
	createuser,
	createartist,
	editua,
	deletetrend,
	changestatus,
	getcontactus,
	deletecontactus,
	replycontactus,
});

export default rootReducer;
