export const bookConstants = {
    GET_SUCCESS: 'BOOK_GET_SUCCESS',
    GET_FAILURE: 'BOOK_GET_FAILURE',
    GET_MY_BOOKS_SUCCESS: 'MY_BOOKS_GET_SUCCESS',
    GET_MY_BOOKS_FAILURE: 'MY_BOOKS_GET_FAILURE',
    GET_BOOK_DETAILS_SUCCESS: 'BOOK_DETAILS_GET_SUCCESS',
    GET_BOOK_DETAILS_FAILURE: 'BOOK_DETAILS_GET_FAILURE',
    NEGOTIATE_BOOK_SUCCESS: 'BOOK_NEGOTIATE_SUCCESS',
    NEGOTIATE_BOOK_FAILURE: 'BOOK_NEGOTIATE_FAILURE',
    ACCEPT_BOOK_SUCCESS: 'BOOK_ACCEPT_SUCCESS',
    ACCEPT_BOOK_FAILURE: 'BOOK_ACCEPT_FAILURE',
    REJECT_BOOK_SUCCESS: 'BOOK_REJECT_SUCCESS',
    REJECT_BOOK_FAILURE: 'BOOK_REJECT_FAILURE',
    PAY_BOOK_SUCCESS:'PAY_BOOK_SUCCESS',
    PAY_BOOK_FAILURE:'PAY_BOOK_FAILURE',
};