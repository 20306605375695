
import { apiConstants } from '../_constants';

export const bookService = {
    specialrequest,
    bookrequest,
    myBooks,
    getBookDetails,
    negotiateBook,
    acceptBook,
    rejectBook,
    payBook
};

//implement special request submission
function specialrequest(info) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            topic:info.topic,
            description:info.description,
            payment:info.payment,
            deliveryDate:info.deliveryDate,
            country:info.country,
            city:info.city,
            tags:info.tags,
            other:info.other,
            userId:info.userId
         })
    };
    return fetch(apiConstants.API_BOOKS_URL+'specialbusiness?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}
//this is to handle the creation of a book service
function bookrequest(info) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({
            topic:info.topic,
            description:info.description,
            payment:info.payment,
            eventDate:info.eventDate,
            country:info.country,
            city:info.city,
            tags:info.tags,
            other:info.other,
            userId:info.userId,
            extipop:info.extipop,
            venue:info.venue,

         })
    };
    return fetch(apiConstants.API_BOOKS_URL+'book?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this is to get the books of a user
function myBooks() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({})
    };
    return fetch(apiConstants.API_BOOKS_URL+'getowned?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this is to get the details of a book
function getBookDetails(bookId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({bookId: bookId})
    };
    return fetch(apiConstants.API_BOOKS_URL+'getdetails?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this is to negotiate on a book
function negotiateBook(negotiate) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            advance: negotiate.advancedPay,
            city: negotiate.city,
            comment: negotiate.comment,
            deliveryDate: negotiate.eventDate,
            extipop: negotiate.exiPop,
            bookId: negotiate.idBook,
            payment: negotiate.payment,
            advancePay: negotiate.advancePay,
            venue: negotiate.venue
        })
    };
    return fetch(apiConstants.API_BOOKS_URL+'negotiate?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this is to accept on a book
function acceptBook(bookId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ bookId: bookId })
    };
    return fetch(apiConstants.API_BOOKS_URL+'accept?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//this is to reject on a book
function rejectBook(bookId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ bookId: bookId })
    };
    return fetch(apiConstants.API_BOOKS_URL+'reject?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function payBook(bookId){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ bookId: bookId })
    };
    return fetch(apiConstants.API_BOOKS_URL+'pay?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
