export const apiConstants = {

    API_POST_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/posts/',
    API_MESSAGE_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/messages/',
    API_BEFORE_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/beforeauths/',
    API_EXTERNALTHINGS_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/externalthings/',
    API_BOOKS_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/books/',
    API_RELATIONS_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/relationships/',
    API_GENERAL_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/generals/',
    API_MUSIC_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/releases/',
    API_EVENT_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/events/',
    API_PLAYLIST_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/playlists/',
    API_ADMINDELETE_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/admindeletions/',
    API_ADMINCREATE_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/admincreations/',
    API_ADMIN_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/admins/',
    API_FINANCIALS_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/finances/',
    API_SEARCH_URL:'https://api.muzikol.com/sayoFinal/api/web/v1/searches/',
    API_URL_ABOUT: "https://api.muzikol.com/sayoFinal/api/web/v1/beforeauths/about",
    API_URL_CONTACT :"https://api.muzikol.com/sayoFinal/api/web/v1/beforeauths/contact",
    
    API_BASE: 'https://api.muzikol.com/sayoFinal/api/web/',
    API_IMAGES: 'https://api.muzikol.com/sayoFinal/api/web/images/',
    API_ADVERT_FILES: 'https://api.muzikol.com/sayoFinal/api/web/adverts/',
    API_DIGITAL_STORES: 'https://api.muzikol.com/sayoFinal/api/web/distributionstores/',
    API_PAYMENT_IMAGES: 'https://api.muzikol.com/sayoFinal/api/web/images/payments/',
    API_ABOUT_IMAGES: 'https://api.muzikol.com/sayoFinal/api/web/about/',
    API_POST_IMAGES: 'https://api.muzikol.com/sayoFinal/api/web/posts/',
    API_PROFILE_IMAGES:'https://api.muzikol.com/sayoFinal/api/web/profile_pictures/',
    API_BACKGROUND_IMAGES:'https://api.muzikol.com/sayoFinal/api/web/background_pictures/',
    API_TRENDINGS:'https://api.muzikol.com/sayoFinal/api/web/trending/',
    API_MUSIC_FILES: 'https://api.muzikol.com/sayoFinal/api/web/musics/',
    API_PLAYLIST_FILES: 'https://api.muzikol.com/sayoFinal/api/web/playlist_covers/',
    API_EVENT_COVER_IMAGES: 'https://api.muzikol.com/sayoFinal/api/web/events/covers/',
    API_EVENT_DES_IMAGES: 'https://api.muzikol.com/sayoFinal/api/web/events/desmaterials/',
    FRONT_VIDEO:'https://api.muzikol.com/sayoFinal/api/web/images/advert.mp4', 
    API_CHART_FILES: 'https://api.muzikol.com/sayoFinal/api/web/chart_covers/',
    //THIS IS FOR THE EXPRESS API
    API_RUNTIME_URL:'https://www.muzikol.com:9020/'


/**    
    API_POST_URL:'http://localhost/sayoFinal/api/web/v1/posts/',
    API_MESSAGE_URL:'http://localhost/sayoFinal/api/web/v1/messages/',
    API_BEFORE_URL:'http://localhost/sayoFinal/api/web/v1/beforeauths/',
    API_EXTERNALTHINGS_URL:'http://localhost/sayoFinal/api/web/v1/externalthings/',
    //API_BEFORE_URL:'https://www.muzikol.com/sayoFinal/api/web/v1/beforeauths/',
    API_BOOKS_URL:'http://localhost/sayoFinal/api/web/v1/books/',
    API_RELATIONS_URL:'http://localhost/sayoFinal/api/web/v1/relationships/',
    API_GENERAL_URL:'http://localhost/sayoFinal/api/web/v1/generals/',
    API_MUSIC_URL:'http://localhost/sayoFinal/api/web/v1/releases/',
    API_EVENT_URL:'http://localhost/sayoFinal/api/web/v1/events/',
    API_PLAYLIST_URL:'http://localhost/sayoFinal/api/web/v1/playlists/',
    API_ADMINDELETE_URL:'http://localhost/sayoFinal/api/web/v1/admindeletions/',
    API_ADMINCREATE_URL:'http://localhost/sayoFinal/api/web/v1/admincreations/',

    // API_ADMIN_URL:'https://www.muzikol.com/sayoFinal/api/web/v1/admins/',
    API_ADMIN_URL:'http://localhost/sayoFinal/api/web/v1/admins/',
    API_FINANCIALS_URL:'http://localhost/sayoFinal/api/web/v1/finances/',
    API_SEARCH_URL:'http://localhost/sayoFinal/api/web/v1/searches/',
    API_URL_ABOUT: "http://localhost/sayoFinal/api/web/v1/beforeauths/about",
    API_URL_CONTACT :"http://localhost/sayoFinal/api/web/v1/beforeauths/contact",

    
    API_BASE: 'http://localhost/sayoFinal/api/web/',
    API_IMAGES: 'http://localhost/sayoFinal/api/web/images/',
    API_ADVERT_FILES: 'http://localhost/sayoFinal/api/web/adverts/',
    API_DIGITAL_STORES: 'http://localhost/sayoFinal/api/web/distributionstores/',
    API_PAYMENT_IMAGES: 'http://localhost/sayoFinal/api/web/images/payments/',
    API_ABOUT_IMAGES: 'http://localhost/sayoFinal/api/web/about/',
    API_POST_IMAGES: 'http://localhost/sayoFinal/api/web/posts/',
    API_PROFILE_IMAGES:'http://localhost/sayoFinal/api/web/profile_pictures/',
    API_ICONS_IMAGES:'http://localhost/sayoFinal/api/web/icons/',
    API_BACKGROUND_IMAGES:'http://localhost/sayoFinal/api/web/background_pictures/',
    API_TRENDINGS:'http://localhost/sayoFinal/api/web/trending/',
    API_MUSIC_FILES: 'http://localhost/sayoFinal/api/web/musics/',
    API_CHART_FILES: 'http://localhost/sayoFinal/api/web/chart_covers/',
    API_EVENT_COVER_IMAGES: 'http://localhost/sayoFinal/api/web/events/covers/',
    API_EVENT_DES_IMAGES: 'http://localhost/sayoFinal/api/web/events/desmaterials/',
    FRONT_VIDEO:'http://localhost/sayoFinal/api/web/images/advert.mp4',

    //THIS IS FOR THE EXPRESS API
    API_RUNTIME_URL:'http://localhost:2800/'
   **/
    
};
