import React from "react";
import {
  Modal,
  ModalBody,
  Fa,
  Navbar,
  NavbarBrand,
  NavbarNav,
  NavbarToggler,
  Collapse,
  NavItem,
  NavLink,
  Container,
  MDBNavItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import { connect } from "react-redux";
import { history } from "../_helpers";
import { apiConstants } from "../_constants";
import { Trans } from "@lingui/macro";
import { withRouter } from "react-router-dom";
import "./UserDashboardNavbar.css";
import DashboardNav from "./Nav/DashboardNav";
import { userActions } from "../_actions";
import { bindActionCreators } from "redux";

class UserDashboardNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_nav_modal: false, //display the sub_nav
      post_modal: false, //display the post form
      collapse: false, // collapse navbar
      isWideEnough: false, // get screen size
      profile_pic: "thumbnail.jpg", //the profile picture of the user
      nav: {},
    };
    this.toggle_sub_nav = this.toggle_sub_nav.bind(this);
    this.post_toggle = this.post_toggle.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    //load the profile picture of the user.
    const { user } = this.props;
    //if user is not login, sent to login page
    if (!user) {
      history.push("/Login");
    }
    //check wether the user's profile picture exist;
    if (user.profile_pic) {
      this.setState({
        profile_pic: user.profile_pic,
      });
    } else {
      this.setState({
        profile_pic: "thumbnail.jpg",
      });
    }

    //get the navbadges numbers
    const { dispatch } = this.props;
    dispatch(userActions.navbadges());

    //active links
    const { location } = this.props;
    if (location.pathname === "/dashboard") {
      this.setState({
        nav: { dashClass: "active" },
      });
    } else if (location.pathname === "/musics") {
      this.setState({
        nav: {
          musicClass: "active",
        },
      });
    } else if (location.pathname === "/" || location.pathname === "/in") {
      this.setState({
        nav: {
          profileClass: "active",
        },
      });
    } else if (location.pathname === "/events") {
      this.setState({
        nav: { eventClass: "active" },
      });
    } else if (location.pathname === "/searching") {
      this.setState({
        nav: { searchClass: "active" },
      });
    } else if (location.pathname === "/settings") {
      this.setState({
        nav: { setClass: "active" },
      });
    }
  }

  toggle_sub_nav() {
    this.setState({
      sub_nav_modal: !this.state.sub_nav_modal,
    });
  }

  //logout function
  logoutAction() {
    const { dispatch } = this.props;
    dispatch(userActions.logout());
  }

  /* toggle() post form */
  post_toggle() {
    this.setState({
      post_modal: !this.state.post_modal,
    });
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  render() {
    const { user, navbadgesinfo } = this.props;
    const { profile_pic, nav } = this.state;
    if (typeof user === "undefined") {
      return (
        <div>
          <h1>loading</h1>
        </div>
      );
    } else {
      return (
        <div>
          {/* ==== Begin Navbar ==== */}
          <Navbar
            right="true"
            light
            color="white"
            expand="md"
            scrolling
            fixed="top"
            className="noPadding"
          >
            <Container fluid>
              <NavLink to="#" style={{ paddingRight: 10 }}>
                <NavbarBrand
                  to="/dashboard"
                  title="Muzikol logo"
                  // onClick={this.toggle_sub_nav}
                  onClick={() => history.push("/dashboard")}
                  style={{ paddingTop: 0, paddingBottom: 0, marginRight: 0 }}
                >
                  <img
                    src="/icon.png"
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                    alt="Muzikol logo"
                  />
                </NavbarBrand>
              </NavLink>
              <NavLink
                to="/searching"
                onClick={() => history.push("/searching")}
                style={{ paddingLeft: 0 }}
              >
                <div className="SearchBox">
                  <p className="searchText">Search Muzikol</p>
                  <div className="searchButton">
                    <b
                      className="fa fa-search noBadge"
                      aria-hidden="true"
                      title="Search Muzikol"
                    ></b>
                  </div>
                </div>
              </NavLink>
              {/* ==== Begin The right part of the navbar ==== */}
              {!this.state.isWideEnough && (
                <NavbarToggler onClick={this.onClick} />
              )}{" "}
              {/* collapse right navbar based on screen size */}
              <Collapse isOpen={this.state.collapse} navbar>
                <NavbarNav right={true} scrolling="true" fixed="top">
                  <DashboardNav navbadgesinfo={navbadgesinfo} />
                  <MDBNavItem className="user-nav-icon noBadge">
                    <MDBDropdown className="iconWithMarginTop">
                      <MDBDropdownToggle nav>
                        <span className="site-second-color visibleLgOnly">
                          <b>{user.name}</b>
                        </span>
                        <img
                          className="nav-pic-sm img-fluid z-depth-1 rounded-circle"
                          src={apiConstants.API_PROFILE_IMAGES + profile_pic}
                          alt={user.name}
                        />
                        <b
                          className="fa fa-caret-down site-second-color"
                          aria-hidden="true"
                        ></b>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu
                        right
                        style={{ width: "100% !important" }}
                      >
                        <MDBDropdownItem
                          href="/profile"
                          onClick={() => history.push("/profile")}
                        >
                          Profile
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          href="/settings"
                          onClick={() => history.push("/settings")}
                        >
                          Settings
                        </MDBDropdownItem>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem
                          href="/musics"
                          onClick={() => history.push("/musics")}
                        >
                          Music Corner
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          href="/events"
                          onClick={() => history.push("/events")}
                        >
                          Events Corner
                        </MDBDropdownItem>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem
                          href="/login"
                          onClick={() => this.logoutAction()}
                          title="Logout of Muzikol"
                        >
                          Logout
                        </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavItem>
                  <NavItem>
                    {/* ==== Begin sub navbar ==== */}
                    <Modal
                      isOpen={this.state.sub_nav_modal}
                      toggle={this.toggle_sub_nav}
                      frame
                      position="left"
                    >
                      <ModalBody className="options site-background">
                        <div>
                          <div className="options-items-group">
                            <ul className="nav justify-content-between">
                              <li className="nav-item">
                                <NavLink
                                  to={"/profile/" + user.username}
                                  onClick={() =>
                                    history.push("/profile/" + user.username)
                                  }
                                  title={user.username}
                                >
                                  <img
                                    className="nav-pic-md img-fluid z-depth-1 rounded-circle"
                                    src={
                                      apiConstants.API_PROFILE_IMAGES +
                                      profile_pic
                                    }
                                    alt="Profile"
                                  />
                                  <p className="d-flex justify-content-center mt-1">
                                    <b className="site-second-color">Profile</b>
                                  </p>
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  to="/dashboard"
                                  onClick={() => history.push("/dashboard")}
                                  title="Home Page"
                                >
                                  <Fa
                                    icon="home"
                                    className="d-flex justify-content-center options-icon"
                                  />
                                  <p className="d-flex justify-content-center mt-1">
                                    <b className="site-second-color">
                                      <Trans>Dashboard</Trans>
                                    </b>
                                  </p>
                                </NavLink>
                              </li>
                              <li className={"nav-item " + nav.musicClass}>
                                <NavLink
                                  to="/musics"
                                  onClick={() => history.push("/musics")}
                                  title="Musics Corner"
                                >
                                  <Fa
                                    icon="music"
                                    className="d-flex justify-content-center options-icon"
                                  />
                                  <p className="d-flex justify-content-center mt-1">
                                    <b className="site-second-color">
                                      <Trans>Musics Corner</Trans>
                                    </b>
                                  </p>
                                </NavLink>
                              </li>
                              <li className={"nav-item " + nav.eventClass}>
                                <NavLink
                                  to="/events"
                                  onClick={() => history.push("/events")}
                                  title="Events Corner"
                                >
                                  <Fa
                                    icon="paw"
                                    className="d-flex justify-content-center options-icon"
                                  />
                                  <p className="d-flex justify-content-center mt-1">
                                    <b className="site-second-color">
                                      <Trans>Events Corner</Trans>
                                    </b>
                                  </p>
                                </NavLink>
                              </li>
                              <li className={"nav-item " + nav.searchClass}>
                                <NavLink
                                  to="/searching"
                                  onClick={() => history.push("/searching")}
                                  title="Discover New things"
                                >
                                  <Fa
                                    icon="diamond"
                                    className="d-flex justify-content-center options-icon"
                                  />
                                  <p className="d-flex justify-content-center mt-1">
                                    <b className="site-second-color">
                                      <Trans> Searching</Trans>
                                    </b>
                                  </p>
                                </NavLink>
                              </li>
                              <li className={"nav-item " + nav.setClass}>
                                <NavLink
                                  to="/settings"
                                  onClick={() => history.push("/settings")}
                                  title="Settings/Updates"
                                >
                                  <Fa
                                    icon="cog"
                                    className="d-flex justify-content-center options-icon"
                                  />
                                  <p className="d-flex justify-content-center mt-1">
                                    <b className="site-second-color">
                                      <Trans>Settings</Trans>
                                    </b>
                                  </p>
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  to="/login"
                                  onClick={() => this.logoutAction()}
                                  title="Logout of Muzikol"
                                >
                                  <Fa
                                    icon="lock"
                                    className="d-flex justify-content-center options-icon"
                                  />
                                  <p className="d-flex justify-content-center mt-1">
                                    <b className="site-second-color">
                                      <Trans>Logout</Trans>
                                    </b>
                                  </p>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>
                    {/* ==== End sub navbar ==== */}
                  </NavItem>
                </NavbarNav>
              </Collapse>
              {/* ==== End The right part of the navbar ==== */}
            </Container>
          </Navbar>
          {/* ==== End Navbar ==== */}
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { navbadgesinfo } = state.navbadges;
  return {
    user,
    navbadgesinfo,
  };
}

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...userActions });
  return { ...actions, dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserDashboardNavbar));
