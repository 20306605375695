import { postConstants } from '../_constants';

let nothing = 'no data';

//get posts
export function postion(state = {nothing}, action) {
    switch (action.type) {
        case postConstants.GET_POST_SUCCESS:
            return {
                postState: true,
                posts: action.posts
            };
        case postConstants.GET_POST_FAILURE:
            return {
                postState: false,
                error:action.error 
            };
        default:
        return state
    }
}

//create a post
export function postcreation(state = {}, action) {
    switch (action.type) {
        case postConstants.POST_CREATE_SUCCESS:
            return {
                postCreateState: true,
                response: action.response
            };
        case postConstants.POST_CREATE_FAILURE:
            return {
                postCreateState: false,
                error:action.error 
            };
        default:
        return state
    }
}

//reply to a post
export function postcommention(state = {}, action) {
    switch (action.type) {
        case postConstants.COMMENT_POST_SUCCESS:
            return {
                postCommentState: true,
            };
        case postConstants.COMMENT_POST_FAILURE:
            return {
                postCommentState: false,
                error:action.error 
            };
        default:
        return state
    }
}

//reply to a comment
export function replycommention(state = {}, action) {
    switch (action.type) {
        case postConstants.COMMENT_REPLY_SUCCESS:
            return {
                replyCommentState: true,
            };
        case postConstants.COMMENT_REPLY_FAILURE:
            return {
                replyCommentState: false,
                error:action.error 
            };
        default:
        return state
    }
}

//like a post
export function postliketion(state = {}, action) {
    switch (action.type) {
        case postConstants.LIKE_POST_SUCCESS:
            return {
                postLikeState: true,
            };
        case postConstants.LIKE_POST_FAILURE:
            return {
                postLikeState: false,
                error:action.error 
            };
        default:
        return state
    }
}

//like a comment
export function commentliketion(state = {}, action) {
    switch (action.type) {
        case postConstants.LIKE_COMMENT_SUCCESS:
            return {
                commentLikeState: true,
            };
        case postConstants.LIKE_COMMENT_FAILURE:
            return {
                commentLikeState: false,
                error:action.error 
            };
        default:
        return state
    }
}

//get details on a post
export function getdetailtion(state = {}, action) {
    switch (action.type) {
        case postConstants.GET_POST_DETAILS_SUCCESS:
            return {
                postState: true,
                details: action.details
            };
        case postConstants.GET_POST_DETAILS_FAILURE:
            return {
                postState: false,
                error:action.error 
            };
        default:
        return state
    }
}

/*  get the replies of a comment.
    get many replyComments, create an array of them and return it.
    The component needing a particular replyComment will find it based on the key(parentId)
*/
const initState = {
    allReplyComments: []
}
export function getreplycommention(state = initState, action) {
    switch (action.type) {
        case postConstants.GET_REPLY_COMMENT_SUCCESS:
            let newReplyComments = state.allReplyComments;
            if(newReplyComments){
                let replyCom = {
                    key: action.parentId,
                    isCode: action.replyComments.isCode,
                    message: action.replyComments.message,
                    replies: action.replyComments.replies
                };
                newReplyComments.push(replyCom);
            }
            return {
                ...state,
                replyCommentState: true,
                replyComments: state.allReplyComments,
            };
        case postConstants.GET_REPLY_COMMENT_FAILURE:
            return {
                replyCommentState: false,
                error:action.error 
            };
        default:
        return state
    }
}

//get all artists
export function getallartistion(state = {}, action) {
    switch (action.type) {
        case postConstants.GET_ARTISTS_SUCCESS:
            return {
                artistsState: true,
                artists: action.artists
            };
        case postConstants.GET_ARTISTS_FAILURE:
            return {
                artistsState: false,
                error:action.error 
            };
        default:
        return state
    }
}


//get all artists
export function getallreleasion(state = {}, action) {
    switch (action.type) {
        case postConstants.GET_RELEASES_SUCCESS:
            return {
                artistsState: true,
                releases: action.releases
            };
        case postConstants.GET_RELEASES_FAILURE:
            return {
                artistsState: false,
                error:action.error 
            };
        default:
        return state
    }
}

//get all artists
export function getallevention(state = {}, action) {
    switch (action.type) {
        case postConstants.GET_EVENTS_SUCCESS:
            return {
                artistsState: true,
                events: action.events
            };
        case postConstants.GET_EVENTS_FAILURE:
            return {
                artistsState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// delete a post
export function postdeletion(state = {}, action) {
    switch (action.type) {
        case postConstants.DELETE_POST_SUCCESS:
            return {
                deleteState: true,
            };
        case postConstants.DELETE_POST_FAILURE:
            return {
                deleteState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// report a post
export function postreportion(state = {}, action) {
    switch (action.type) {
        case postConstants.REPORT_POST_SUCCESS:
            return {
                reportState: true,
            };
        case postConstants.REPORT_POST_FAILURE:
            return {
                reportState: false,
                error:action.error 
            };
        default:
        return state
    }
}

// update a post
export function postupdation(state = {}, action) {
    switch (action.type) {
        case postConstants.UPDATE_POST_SUCCESS:
            return {
                updateState: true,
                response: action.response
            };
        case postConstants.UPDATE_POST_FAILURE:
            return {
                updateState: false,
                error:action.error 
            };
        default:
        return state
    }
}
