import { bookConstants } from '../_constants';
import { bookService } from '../_services';
import { alertActions } from './';

export const bookActions = {
   specialrequest,
   bookrequest,
   myBooks,
   getBookDetails,
   negotiateBook,
   acceptBook,
   rejectBook,
   payBook
};

//this function is to create specialbusiness request
function specialrequest(info){
    return dispatch => {
        bookService.specialrequest(info)
            .then(
                datas => { 
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(datas) { return { type: bookConstants.GET_SUCCESS, datas } }
    function failure(error) { return { type: bookConstants.GET_FAILURE, error } }
}

//this function is to create a book request
function bookrequest(info, userId){
    return dispatch => {
        bookService.bookrequest(info)
            .then(
                datas => { 
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(datas) { return { type: bookConstants.GET_SUCCESS, datas } }
    function failure(error) { return { type: bookConstants.GET_FAILURE, error } }
}

//this function is to display the books of a user
function myBooks(){
    return dispatch => {
        bookService.myBooks()
            .then(
                datas => { 
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(datas) { return { type: bookConstants.GET_MY_BOOKS_SUCCESS, datas } }
    function failure(error) { return { type: bookConstants.GET_MY_BOOKS_FAILURE, error } }
}

//this function is to get the details on a book
function getBookDetails(idBooking){
    return dispatch => {
        bookService.getBookDetails(idBooking)
            .then(
                datas => { 
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(bookDetails) { return { type: bookConstants.GET_BOOK_DETAILS_SUCCESS, bookDetails } }
    function failure(error) { return { type: bookConstants.GET_BOOK_DETAILS_FAILURE, error } }
}

//this function is to negotiate on a book
function negotiateBook(negotiate){
    return dispatch => {
        bookService.negotiateBook(negotiate)
            .then(
                datas => { 
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(response) { return { type: bookConstants.NEGOTIATE_BOOK_SUCCESS, response } }
    function failure(error) { return { type: bookConstants.NEGOTIATE_BOOK_FAILURE, error } }
}

//this function is to accept on a book
function acceptBook(bookId){
    return dispatch => {
        bookService.acceptBook(bookId)
            .then(
                datas => { 
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(bookResponse) { return { type: bookConstants.ACCEPT_BOOK_SUCCESS, bookResponse } }
    function failure(error) { return { type: bookConstants.ACCEPT_BOOK_FAILURE, error } }
}

//this function is to reject on a book
function rejectBook(bookId){
    return dispatch => {
        bookService.rejectBook(bookId)
            .then(
                datas => {
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(bookResponse) { return { type: bookConstants.REJECT_BOOK_SUCCESS, bookResponse } }
    function failure(error) { return { type: bookConstants.REJECT_BOOK_FAILURE, error } }
}

//this function is to pay book request 
//this function is to reject on a book
function payBook(bookId){
    return dispatch => {
        bookService.payBook(bookId)
            .then(
                datas => {
                   dispatch(success(datas));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
    function success(bookResponse) { return { type: bookConstants.PAY_BOOK_SUCCESS, bookResponse } }
    function failure(error) { return { type: bookConstants.PAY_BOOK_FAILURE, error } }
}
