import { apiConstants } from '../_constants';

export const messageService = {
    inbox,
    messageDetails,
    replyMessage,
    sentMessages,
    unreadMessages,
    threadMessages,
    composeMail,
    getAllUsers,
    replyThread,
    createChat,
    sendChat,
    chatDetails,
    getChatsAll,

};

// get inbox
function inbox() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ })
    };
    return fetch(apiConstants.API_MESSAGE_URL+'inbox?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// get details of a message
function messageDetails(parentId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ parentId })
    };
    return fetch(apiConstants.API_MESSAGE_URL+'messagedetails?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// reply to a message
function replyMessage(reply, parentId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reply, parentId })
    };
    return fetch(apiConstants.API_MESSAGE_URL+'reply?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        }
    );
}

// messages sent by the user
function sentMessages() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ })
    };
    return fetch(apiConstants.API_MESSAGE_URL+'allsents?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// unread messages
function unreadMessages() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ })
    };
    return fetch(apiConstants.API_MESSAGE_URL+'unreadmessages?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// thread messages
function threadMessages(parentId) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ parentId })
    };
    return fetch(apiConstants.API_MESSAGE_URL+'threadmessages?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// compose a mail
function composeMail(email, receiver) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topic: email.topic, body: email.body, receiver: receiver})
    };
    return fetch(apiConstants.API_MESSAGE_URL+'composemail?access-token='+user.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        }
    );
}

// get all users
function getAllUsers() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ })
    };
    return fetch(apiConstants.API_MESSAGE_URL+'getallusers?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        }
    );
}

// reply to a thread
function replyThread(reply, idThread) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reply, idThread })
    };
    return fetch(apiConstants.API_MESSAGE_URL+'replythread?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// send a chat message
function createChat(username, message) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, message })
    };
    return fetch(apiConstants.API_MESSAGE_URL+'createchat?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// send a chat message
function sendChat(chatId, message) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ chatId, message })
    };
    return fetch(apiConstants.API_MESSAGE_URL+'chatsend?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// get messages of a chat
function chatDetails(username) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username })
    };
    return fetch(apiConstants.API_MESSAGE_URL+'chatdetials?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// get latest chats and friends
function getChatsAll() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
//        body: JSON.stringify({  })
    };
    return fetch(apiConstants.API_MESSAGE_URL+'chatsall?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}