
import { apiConstants } from '../_constants';

export const financialService = {
    gift,
    request,
    statistics,
    getpendings,
    acceptGift,
    declineGift,
    withdrawal,
    yamard,
    mobilemoneydepo,
    promoDeposit
};

//implement special request submission
function gift(amount, username) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            amount, username
         })
    };
    return fetch(apiConstants.API_FINANCIALS_URL+'directgift?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}


//implement special request submission
function request(amount, username) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            amount, username
         })
    };
    return fetch(apiConstants.API_FINANCIALS_URL+'request?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//implement special request submission
function statistics() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(apiConstants.API_FINANCIALS_URL+'statistics?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

// accept a gift request
function acceptGift(idGift) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            'idGift': idGift
        })
    };
    return fetch(apiConstants.API_FINANCIALS_URL+'acceptrequest?access-token='+user.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}

// decline a gift request
function declineGift(idGift) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            'idGift': idGift
        })
    };
    return fetch(apiConstants.API_FINANCIALS_URL+'acceptrequest?access-token='+user.auth_key, requestOptions)
        .then(handleResponse)
        .then(json => {
            return json;
        });
}

//implement special request submission
function getpendings() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(apiConstants.API_FINANCIALS_URL+'pendings?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

//withdraw money from your account using world remit, 
function yamard(info) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify(info)
    };
    return fetch(apiConstants.API_FINANCIALS_URL+'withdrawalrequest?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}


//withdraw money from your account
function withdrawal(phoneNumber, amount, password,  method) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({
            phoneNumber, amount, password,  method
        })
    };
    return fetch(apiConstants.API_FINANCIALS_URL+'withdrawalrequest?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}


//withdraw money from your account
function mobilemoneydepo(amount, phoneNumber, method) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({
            amount, phoneNumber, method
        })
    };
    return fetch(apiConstants.API_FINANCIALS_URL+'momodeposit?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
        });
}

function promoDeposit(promocode){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify({
            promocode
        })
    };
    return fetch(apiConstants.API_FINANCIALS_URL+'creditpromocode?access-token='+user.auth_key, requestOptions)
         .then(handleResponse)
        .then(json => {
            return json;
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}