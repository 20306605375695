import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';

let loggerMiddleware = createLogger();

let innerWare  =  applyMiddleware(
	        thunkMiddleware,
	        loggerMiddleware
	    );
if(process.env.NODE_ENV === 'production'){
	innerWare  =  applyMiddleware(
	        thunkMiddleware,
	        //loggerMiddleware
	);
}


export const store = createStore(
	    rootReducer,
	    innerWare,
	   
	);