export const relationsConstants = {
    FREQUEST_SUCCESS: 'FRIEND_REQUEST_SUCCESS',
    FREQUEST_FAILURE: 'FRIEND_REQUEST_FAILURE',
    FOLLOW_SUCCESS: 'FOLLOW_SUCCESS',
    FOLLOW_FAILURE: 'FOLLOW_FAILURE',
    GET_ALL_FRIENDS_SUCCESS: 'ALL_FRIENDS_GET_SUCCESS',
    GET_ALL_FRIENDS_FAILURE: 'ALL_FRIENDS_GET_FAILURE',
    GET_ALL_FRIEND_REQUEST_SUCCESS: 'ALL_FRIEND_REQUEST_GET_SUCCESS',
    GET_ALL_FRIEND_REQUEST_FAILURE: 'ALL_FRIEND_REQUEST_GET_FAILURE',
    ACCEPT_FRIEND_REQUEST_SUCCESS: 'FRIEND_REQUEST_ACCEPT_SUCCESS',
    ACCEPT_FRIEND_REQUEST_FAILURE: 'FRIEND_REQUEST_ACCEPT_FAILURE',
    DECLINE_FRIEND_REQUEST_SUCCESS: 'FRIEND_REQUEST_DECLINE_SUCCESS',
    DECLINE_FRIEND_REQUEST_FAILURE: 'FRIEND_REQUEST_DECLINE_FAILURE',

};