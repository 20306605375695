export const postConstants = {
   
    GET_POST_SUCCESS: 'POST_GET_SUCCESS',
    GET_POST_FAILURE: 'POST_GET_FAILURE',
    DELETE_POST_SUCCESS: 'POST_DELETE_SUCCESS',
    DELETE_POST_FAILURE: 'POST_DELETE_FAILURE',
    REPORT_POST_SUCCESS: 'POST_REPORT_SUCCESS',
    REPORT_POST_FAILURE: 'POST_REPORT_FAILURE',
    POST_CREATE_SUCCESS: 'POST_CREATE_SUCCESS',
    POST_CREATE_FAILURE: 'POST_CREATE_FAILURE',
    UPDATE_POST_SUCCESS: 'POST_UPDATE_SUCCESS',
    UPDATE_POST_FAILURE: 'POST_UPDATE_FAILURE',
    COMMENT_POST_SUCCESS: 'POST_COMMENT_SUCCESS',
    COMMENT_POST_FAILURE: 'POST_COMMENT_FAILURE',
    COMMENT_REPLY_SUCCESS: 'REPLY_COMMENT_SUCCESS',
    GET_REPLY_COMMENT_SUCCESS: 'REPLY_COMMENT_GET_SUCCESS',
    GET_REPLY_COMMENT_FAILURE: 'REPLY_COMMENT_GET_FAILURE',
    GET_POST_DETAILS_SUCCESS: 'POST_DETAILS_GET_SUCCESS',
    GET_POST_DETAILS_FAILURE: 'POST_DETAILS_GET_FAILURE',
    LIKE_POST_SUCCESS: 'POST_LIKE_SUCCESS',
    LIKE_POST_FAILURE: 'POST_LIKE_FAILURE',
    LIKE_COMMENT_SUCCESS: 'COMMENT_LIKE_SUCCESS',
    LIKE_COMMENT_FAILURE: 'COMMENT_LIKE_FAILURE',
    GET_ARTISTS_SUCCESS: 'ARTISTS_GET_SUCCESS',
    GET_ARTISTS_FAILURE: 'ARTISTS_GET_FAILURE',
    GET_EVENTS_SUCCESS: 'EVENTS_GET_SUCCESS',
    GET_EVENTS_FAILURE: 'EVENTS_GET_FAILURE',
    GET_RELEASES_SUCCESS: 'RELEASES_GET_SUCCESS',
    GET_RELEASES_FAILURE: 'RELEASES_GET_FAILURE',
};
