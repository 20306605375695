import { eventConstants } from '../_constants';

export function evention(state = {}, action) {
    switch (action.type) {
        case eventConstants.GET_SUCCESS:
            return {
                eventState: true,
                events: action.events,
            };
        case eventConstants.GET_FAILURE:
            return {
                eventState: false,
                error:action.error 
            };
        default:
          return state
    }
}

export function eventdetion(state={}, action){
    switch (action.type) {
        case eventConstants.GET_ITEM_SUCCESS:
            return {
                eventState: true,
                eventitem: action.eventitem
            };
        case eventConstants.GET_ITEM_FAILURE:
            return {
                eventState: false,
                error:action.error 
            };
        default:
          return state
    }
}

export function eventmessage(state={}, action){
    switch (action.type) {
        case eventConstants.GET_ITEM_SUCCESS:
            return {
                emessageState: true,
                eventmessage: action.eventmessage
            };
        case eventConstants.GET_ITEM_FAILURE:
            return {
                rmessageState: false,
                error:action.error 
            };
        default:
          return state
    }
}

export function eventattending(state = {}, action) {
    switch (action.type) {
        case eventConstants.GET_SUCCESS:
            return {
                eventState: true,
                attendings: action.attendings,
            };
        case eventConstants.GET_FAILURE:
            return {
                eventState: false,
                error:action.error 
            };
        default:
          return state
    }
}

