import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user, userType: user.user_type} : {};


export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:  
      return {
        loggedIn: true,
        user: action.user,
        userType:action.userType,
      };
    case userConstants.USER_UPDATE_SUCCESS:  
      return {
        updatedState: true,
        user: action.user
      };
      case userConstants.USER_SUCCESS:  
      return {
        updatedState: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
          loggedIn: false,
          error:action.error 
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}


//this function is to get the relationship users set
export function updatemessage(state={}, action){
    switch (action.type) {
        case userConstants.USER_SUCCESS:
            return {
                updatedState: true,
                updatemessage: action.updatemessage
            };
        case userConstants.USER_PARTIAL_SUCCESS:
            return {
                updatedState: true,
                updatemessage: action.updatemessage
            };
        case userConstants.USER_FAILURE:
            return {
                updatedState: false,
                error:action.error 
            };
        default:
          return state
    }
}

export function loadingReducer(state = {}, action) {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
  
  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;  
  
  const [, requestName, requestState] = matches;
  return {
    ...state,
    preloader:true,
    [requestName]: requestState === 'REQUEST',
  };
};
