import { financialConstants } from '../_constants';

export function financialmessage(state={}, action){
    switch (action.type) {
        case financialConstants.GET_SUCCESS:
            return {
                fmessageState: true,
                financialmessage: action.financialmessage
            };
        case financialConstants.GET_FAILURE:
            return {
                fmessageState: false,
                error:action.error 
            };
        default:
          return state
    }
}

export function statistics(state={}, action){
    switch (action.type) {
        case financialConstants.GET_SUCCESS:
            return {
                fmessageState: true,
                statisticsdata: action.statisticsdata
            };
        case financialConstants.GET_FAILURE:
            return {
                fmessageState: false,
                error:action.error 
            };
        default:
          return state
    }
}

export function getpendings(state={}, action){
    switch (action.type) {
        case financialConstants.GET_ALL_GIFT_REQUEST_SUCCESS:
            return {
                fmessageState: true,
                pendings: action.pendings
            };
        case financialConstants.GET_ALL_GIFT_REQUEST_FAILURE:
            return {
                fmessageState: false,
                error:action.error 
            };
        default:
          return state
    }
}

export function acceptgift(state={}, action){
    switch (action.type) {
        case financialConstants.ACCEPT_GIFT_SUCCESS:
            return {
                giftAcceptState: true,
                response: action.response
            };
        case financialConstants.ACCEPT_GIFT_FAILURE:
            return {
                giftAcceptState: false,
                error:action.error 
            };
        default:
          return state
    }
}

export function declinegift(state={}, action){
    switch (action.type) {
        case financialConstants.DECLINE_GIFT_SUCCESS:
            return {
                giftDeclineState: true,
                response: action.response
            };
        case financialConstants.DECLINE_GIFT_FAILURE:
            return {
                giftDeclineState: false,
                error:action.error 
            };
        default:
          return state
    }
}