import { bookConstants } from '../_constants';

export function booktion(state = {}, action) {
  switch (action.type) {
    case bookConstants.GET_SUCCESS:
      return {
        bookState: true,
        bookdata: action.datas
      };
      case bookConstants.GET_FAILURE:
        return {
          bookState: false,
          error:action.error 
        };
    default:
      return state
  }
}

export function mybookstion(state = {}, action) {
  switch (action.type) {
    case bookConstants.GET_MY_BOOKS_SUCCESS:
      return {
        bookState: true,
        mybooks: action.datas
      };
      case bookConstants.GET_MY_BOOKS_FAILURE:
        return {
          bookState: false,
          error:action.error 
        };
    default:
      return state
  }
}

// get the details of a book
export function bookDetailstion(state = {}, action) {
  switch (action.type) {
    case bookConstants.GET_BOOK_DETAILS_SUCCESS:
      return {
        bookState: true,
        bookDetails: action.bookDetails
      };
      case bookConstants.GET_BOOK_DETAILS_FAILURE:
        return {
          bookState: false,
          error:action.error 
        };
    default:
      return state
  }
}

// accept a book
export function acceptbooktion(state = {}, action) {
  switch (action.type) {
    case bookConstants.ACCEPT_BOOK_SUCCESS:
      return {
        bookState: true,
        bookResponse: action.bookResponse
      };
      case bookConstants.ACCEPT_BOOK_FAILURE:
        return {
          bookState: false,
          error:action.error 
        };
    default:
      return state
  }
}

// reject a book
export function rejectbooktion(state = {}, action) {
  switch (action.type) {
    case bookConstants.REJECT_BOOK_SUCCESS:
      return {
        bookState: true,
        bookResponse: action.bookResponse
      };
      case bookConstants.REJECT_BOOK_FAILURE:
        return {
          bookState: false,
          error:action.error 
        };
    case bookConstants.PAY_BOOK_SUCCESS:
      return {
        bookState: true,
        bookResponse: action.bookResponse,
        bookMessage:action.bookResponse.message
      };
    case bookConstants.PAY_BOOK_FAILURE:
      return {
          bookState: false,
          error:action.error
      };
    default:
      return state
  }
}
/*
// reject a book
export function paybooktion(state = {}, action) {
  switch (action.type) {
    case bookConstants.PAY_BOOK_SUCCESS:
      return {
        bookState: true,
        bookResponse: action.bookResponse
      };
      case bookConstants.SUCCESS_BOOK_FAILURE:
        return {
          bookState: false,
          error:action.error 
        };
    default:
      return state
  }
}*/