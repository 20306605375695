export const adminConstants = {
    GET_PENDING_ACCOUNTS_SUCCESS: 'PENDING_ACCOUNTS_GET_SUCCESS',
    GET_MESSAGE_SUCCESS:'GET_MESSAGE_SUCCESS',
    GET_MESSAGE_FAILURE:'GET_MESSAGE_FAILURE',
    GET_PENDING_ACCOUNTS_FAILURE: 'PENDING_ACCOUNTS_GET_FAILURE',
	ACTIVATE_ACCOUNTS_SUCCESS: 'ACCOUNTS_ACTIVATE_SUCCESS',
    ACTIVATE_ACCOUNTS_FAILURE: 'ACCOUNTS_ACTIVATE_FAILURE',
    GET_PENDING_WITHDRAWALS_SUCCESS: 'PENDING_WITHDRAWALS_GET_SUCCESS',
    GET_PENDING_WITHDRAWALS_FAILURE: 'PENDING_WITHDRAWALS_GET_FAILURE',
    ACCEPT_WITHDRAWAL_SUCCESS: 'WITHDRAWAL_ACCEPT_SUCCESS',
    ACCEPT_WITHDRAWAL_FAILURE: 'WITHDRAWAL_ACCEPT_FAILURE',
    REJECT_WITHDRAWAL_SUCCESS: 'WITHDRAWAL_REJECT_SUCCESS',
    REJECT_WITHDRAWAL_FAILURE: 'WITHDRAWAL_REJECT_FAILURE',
    GET_COMPANY_STAFF_SUCCESS: 'COMPANY_STAFF_GET_SUCCESS',
    GET_COMPANY_STAFF_FAILURE: 'COMPANY_STAFF_GET_FAILURE',
    GET_TRENDING_SUCCESS: 'TRENDING_GET_SUCCESS',
    GET_TRENDING_FAILURE: 'TRENDING_GET_FAILURE',
    GET_BLOCKED_USERS_SUCCESS: 'BLOCKED_USERS_GET_SUCCESS',
    GET_BLOCKED_USERS_FAILURE: 'BLOCKED_USERS_GET_FAILURE',
    UNBLOCK_USER_SUCCESS: 'USER_UNBLOCK_SUCCESS',
    UNBLOCK_USER_FAILURE: 'USER_UNBLOCK_FAILURE',
    GET_BOOKS_SUCCESS: 'BOOKS_GET_SUCCESS',
    GET_BOOKS_FAILURE: 'BOOKS_GET_FAILURE',
    SAVE_BOOK_SUCCESS: 'BOOK_SAVE_SUCCESS',
    SAVE_BOOK_FAILURE: 'BOOK_SAVE_FAILURE',
    DELETE_BOOK_SUCCESS: 'BOOK_DELETE_SUCCESS',
    DELETE_BOOK_FAILURE: 'BOOK_DELETE_FAILURE',
    GET_RELEASES_SUCCESS: 'RELEASES_GET_SUCCESS',
    GET_RELEASES_FAILURE: 'RELEASES_GET_FAILURE',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE',
    GET_ALL_USERS_SUCCESS: 'ALL_USERS_GET_SUCCESS',
    GET_ALL_USERS_FAILURE: 'ALL_USERS_GET_FAILURE',
    CREATE_USER_SUCCESS: 'USER_CREATE_SUCCESS',
    CREATE_USER_FAILURE: 'USER_CREATE_FAILURE',
    EDIT_USER_SUCCESS: 'USER_EDIT_SUCCESS',
    EDIT_USER_FAILURE: 'USER_EDIT_FAILURE',
    CHANGE_STATUS_SUCCESS: 'STATUS_CHANGE_SUCCESS',
    CHANGE_STATUS_FAILURE: 'STATUS_CHANGE_FAILURE',
    GET_CONTACT_US_SUCCESS: 'CONTACT_US_GET_SUCCESS',
    GET_CONTACT_US_FAILURE: 'CONTACT_US_GET_FAILURE',
    DELETE_CONTACT_US_SUCCESS: 'CONTACT_US_DELETE_SUCCESS',
    DELETE_CONTACT_US_FAILURE: 'CONTACT_US_DELETE_FAILURE',
    REPLY_CONTACT_US_SUCCESS: 'CONTACT_US_REPLY_SUCCESS',
    REPLY_CONTACT_US_FAILURE: 'CONTACT_US_REPLY_FAILURE',
};